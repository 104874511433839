import * as React from 'react';
import { ModalBody, Modal, ModalFooter, Button } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import MainContext from 'components/layout/MainContext';
import Pdf from 'components/reports/Pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import useSalas from 'hooks/useSalas';
import ISala from 'types/ISala';
import DatePicker from 'react-datepicker';
import { SyntheticEvent } from 'react';
import ICongregation from 'types/ICongregation';
import useCongregation from 'hooks/useCongregation';
import useReportData from 'hooks/useReportData';
import PdfS89S from 'components/reports/PdfS89S';
import { useTranslation } from 'react-i18next';

export default function Reports (): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const { selectedDate } = React.useContext(MainContext);
    const [month, setMonth] = React.useState(selectedDate);
    const [color, setColor] = React.useState(true);
    const [s89s, setS89s] = React.useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const { data } = useCongregation();
    const congregation = data as ICongregation;

    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];

    const handleColorChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        setColor(target.checked);
    };

    const handleS89SChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        setS89s(target.checked);
    };

    const reportData = useReportData(month, currentLanguage);

    return (
        <>
            <Button color="primary" onClick={toggle}><i className="far fa-file-pdf pr-2" />{t('program.download', 'Descargar')}</Button>
            <Modal isOpen={isOpen} toggle={toggle} size="sm" centered>
                <ModalBody className="p-4 d-flex flex-column">
                    <small><b>{t('program.select-month', 'Seleccione el mes')}:</b></small>
                    <DatePicker
                        selected={month}
                        onChange={(date: Date) => setMonth(date)}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        locale={currentLanguage === 'es-US' ? es : en}
                        className="month-picker"
                    />
                    <div className="mt-2 text-right">
                        <div className="pretty p-default">
                            <input type="checkbox" name="color" checked={color} onChange={handleColorChange} />
                            <div className="state p-primary">
                                <label><small><b>{t('program.color', 'Color')}</b></small></label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 text-right">
                        <div className="pretty p-default">
                            <input type="checkbox" name="color" checked={s89s} onChange={handleS89SChange} />
                            <div className="state p-primary">
                                <label><small><b>{t('program.s-89', 'S-89-S')}</b></small></label>
                            </div>
                        </div>
                    </div>
                    {s89s && (
                        <small className="mt-3">
                            <b>{t('program.suggestion', 'Sugerencia')}: </b>{t('program.print-message', 'Para imprimir este documento se recomienda hacerlo en 4 páginas por hoja.')}<br/>
                            <a href="https://www.youtube.com/watch?v=Wy5L3XUUVo8" target="_blank" rel="noreferrer">{t('program.sample-tutorial', 'Tutorial de ejemplo')}</a><br/>
                            {t('program.print-message-difference', '(Puede variar según la impresora)')}
                        </small>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={toggle}>{t('general.close', 'Cerrar')}</Button>
                    {s89s ? (
                        <PDFDownloadLink
                            document={<PdfS89S data={reportData} salas={salas} />}
                            fileName={`VMC-s89s-${format(reportData[0]?.date, 'MMMM', { locale: es })}-${reportData[0]?.date.getFullYear()}.pdf`}
                        >
                            {({ loading }) => loading ? '' : (<Button color="primary" onClick={toggle}>{t('program.download', 'Descargar')}</Button>)}
                        </PDFDownloadLink>
                    ):(
                        <PDFDownloadLink
                            document={<Pdf data={reportData} congregation={congregation} salas={salas} color={color}/>}
                            fileName={`VMC-${format(reportData[0]?.date, 'MMMM', { locale: es })}-${reportData[0]?.date.getFullYear()}.pdf`}
                        >
                            {({ loading }) => loading ? '' : (<Button color="primary" onClick={toggle}>{t('program.download', 'Descargar')}</Button>)}
                        </PDFDownloadLink>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

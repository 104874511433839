import * as React from 'react';
import styled from 'styled-components';
import ProfileButton from './ProfileButton';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import AssignButton from './AssignButton';
import { findAssignedName, findAssignedId, getMonday, findAssignmentId, getIsEstudiantil } from 'services/utils';
import useAssignments from 'hooks/useAssignments';
import IAssignment from 'types/IAssignment';
import MainContext from 'components/layout/MainContext';
import { useMutation, useQueryClient } from 'react-query';
import deleteAsignacion from 'hooks/deleteAsignacion';
import useCanAssign from 'hooks/useCanAssign';
import ViewAssignementS89S from './ViewAssignementS89S';

interface Props {
    type: number,
    isAux?: boolean,
}

export default function Assignment ({ type, isAux }: Props): JSX.Element {
    const { selectedDate } = React.useContext(MainContext);
    const monday = getMonday(selectedDate);
    const isSessionOpen = useIsSessionOpen();
    const { data: assignmentsData } = useAssignments(monday);
    const assignments = assignmentsData as IAssignment[];

    const id = findAssignmentId(type, assignments);

    const canAssign = useCanAssign(type);

    const isEstudiantil = getIsEstudiantil(type);

    const queryClient = useQueryClient();

    const mutationDelete = useMutation(deleteAsignacion, {
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['asignments', monday]);
        },
    });

    const handleDelete = () => {
        if (id) {
            mutationDelete.mutate(id);
        }
    };

    if (!assignments) return <></>;

    const assignedId = findAssignedId(type, assignments);

    if (isSessionOpen) {
        return (
            <div className={isAux ? 'assigned-aux' : 'assigned'}>
                {assignedId ? (
                    <>
                        {canAssign && <ButtonStyled onClick={handleDelete}><i className="far fa-times-circle" /></ButtonStyled>}
                        <ProfileButton id={assignedId} className={isAux ? 'assigned-aux' : undefined} />
                        {isEstudiantil && !isAux && id && <ViewAssignementS89S id={id} />}
                    </>
                ):(
                    canAssign && <AssignButton id={type} />
                )}
            </div>
        );
    }

    return (
        <div className={isAux ? 'assigned-aux' : 'assigned'}>
            {findAssignedName(type, assignments)}
        </div>

    );
}

const ButtonStyled = styled.button`
    display: inline;
    background: none;
    border: none;
`;

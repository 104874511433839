import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import ICongregation from 'types/ICongregation';

export default function useCongregations (): UseQueryResult {
    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                congregaciones{
                    id
                    nombre
                    pais
                }
            }
        `);

        return response.congregaciones;
    };

    return useQuery<ICongregation[], Error>(['congregations'], loadData);
}


import useCongregationId from 'hooks/useCongregationId';
import { Redirect } from 'react-router-dom';
import useCongregation from 'hooks/useCongregation';
import ICongregation from 'types/ICongregation';
import Meeting from 'components/general/Meeting';

export default function MainPage (): JSX.Element {
    const congregationId = useCongregationId();
    const { data: congregationData } = useCongregation();
    const congregation = congregationData as ICongregation;

    if (!congregationId) {
        return <Redirect to="/menu-congregaciones" />;
    }

    return (
        <>
            <div className="mt-md-4 d-flex justify-content-end pb-4">
                <b style={{textAlign: 'right'}}>
                    {congregation?.nombre}
                    <br/>
                    {congregation?.pais}
                </b>
            </div>
            <Meeting />
        </>
    );
}


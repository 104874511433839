import * as React from 'react';
import useEventos from 'hooks/useEventos';
import IEvento from 'types/IEvento';
import { getEventoText, getDateRangeFormatted } from 'services/utils';
import DeleteEventoButton from 'components/general/DeleteEventoButton';
import ModalCreateEvento from 'components/general/ModalCreateEvento';
import { Eventos } from 'services/constants';
import { useTranslation } from 'react-i18next';

export default function SettingsEventos (): JSX.Element {
    const {data: dataEventos} = useEventos();
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const allData = dataEventos as IEvento[] || [];
    const data = allData.filter((item) => {
        return item.tipo !== Eventos.NOTA;
    });

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-between align-items-end mb-1">
                <small><b>{t('event.events', 'Eventos')}</b></small>
                <ModalCreateEvento />
            </div>
            {data?.map((item: IEvento) => (
                <div key={item.id} className="border py-2 pl-3 d-flex justify-content-between mb-2">
                    <div>
                        <div>{getDateRangeFormatted(new Date(item.fecha), currentLanguage)}</div>
                        <div><small>{item.tipo === Eventos.OTRO ? item.observaciones : getEventoText(item.tipo, t)}</small></div>
                    </div>
                    <DeleteEventoButton id={item.id}/>
                </div>
            ))}
        </div>
    );
}

import * as React from 'react';
import IconButton from 'components/general/IconButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import deleteEvento from 'hooks/deleteEvento';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number,
}

export default function DeleteEventoButton ({id}: Props): JSX.Element {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [show, setShow] = React.useState(false);

    const toggle = () => setShow(!show);

    const mutationDelete = useMutation(deleteEvento, {
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEventos');
            toggle();
        },
    });

    const handleDelete = () => {
        mutationDelete.mutate(id);
    };

    return (
        <>
            <IconButton onClick={toggle} icon="fas fa-trash-alt" />

            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText={t('general.yes-confirm', 'Sí, confirmo')}
                cancelBtnText={t('general.no-back', 'No, volver')}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title=""
                onConfirm={handleDelete}
                onCancel={toggle}
            >
                {t('general.sure-delete', '¿Está seguro de eliminar?')}
            </SweetAlert>
        </>
    );
}

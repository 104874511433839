import * as React from 'react';
import useEventos from 'hooks/useEventos';
import IEvento from 'types/IEvento';
import DeleteEventoButton from 'components/general/DeleteEventoButton';
import { Eventos, Roles } from 'services/constants';
import ModalCreateNota from 'components/general/ModalCreateNota';
import MainContext from 'components/layout/MainContext';
import { getMonday, getUTC } from 'services/utils';
import useIsRole from 'hooks/useIsRole';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import { useTranslation } from 'react-i18next';

export default function SettingsNotas (): JSX.Element {
    const { selectedDate } = React.useContext(MainContext);
    const {data: dataEventos} = useEventos();
    const allData = dataEventos as IEvento[] || [];
    const data = allData.filter((item) => {
        return item.tipo === Eventos.NOTA && getMonday(getUTC(new Date(item.fecha))) === getMonday(selectedDate);
    });
    const { t } = useTranslation();

    const isCoordinador = useIsRole(Roles.COORDINADOR);
    const isSessionOpen = useIsSessionOpen();

    return (
        <div className="mt-4">
            {isSessionOpen && isCoordinador ? (
                <div className="mb-1">
                    <ModalCreateNota />
                </div>
            ):(
                <>{data.length ? <small><b><i>{t('program.observations', 'Observaciones')}:</i></b></small> : <></>}</>
            )}
            {data?.map((item: IEvento) => (
                <div key={item.id} className="border-bottom py-2 pl-3 d-flex justify-content-between mb-2">
                    <div>{item.observaciones}</div>
                    {isSessionOpen && isCoordinador && <DeleteEventoButton id={item.id}/>}
                </div>
            ))}
        </div>
    );
}

import styled from 'styled-components';

interface Props {
    onClick: () => void,
    icon: string,
    disabled?: boolean,
}

export default function IconButton ({ onClick, icon, disabled }: Props): JSX.Element {
    return (
        <Button disabled={disabled} onClick={onClick}><i className={icon} /></Button>
    );
}

const Button = styled.button`
    background-color: transparent;
    border: none;
    padding: 0 12px;;

    i{
        width: 25px;
    }
`;

import { createGlobalStyle } from 'styled-components';
import theme from 'theme';
import './index.scss';

const GlobalStyles = createGlobalStyle`
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Noto Sans', sans-serif;
    }

    h1, h2{
        font-weight: bold;

        @media(max-width: 768px){
            font-size: 20px;
        }
    }

    h1{
        @media(max-width: 768px){
            font-size: 20px;
        }
    }

    h2{
        @media(max-width: 768px){
            font-size: 16px;
        }
    }

    a{
       text-decoration: none;
       cursor: pointer;
       color: #4a6da7;

       &:hover{
            color: #4a6da7;
            text-decoration: underline;
       }

    }

    .react-bootstrap-table{
        th{
            user-select: none;
            cursor: pointer;
            &:focus{
                outline: 0;
            }

            span {
                float: right;
            }
        }
        .filter-label{
            width: 100%;
            margin-top: 10px;
        }
    }

    .nav-tabs{
        cursor: pointer;
    }

    .sidebar {
        z-index: 100 !important;
    }

    .overlay {
        z-index: 50 !important;
    }

    .form-control.is-invalid {
        background-image: none;
        padding-right: 1rem;
    }

    strong {
        font-weight: bold;
    }

    /* PRETTY CHECKBOX */
    .pretty{
        &.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
            background-color: ${theme.primary} !important;
        }

        & input:checked ~ .state.p-primary-o label:before,
        &.p-toggle .state.p-primary-o label:before {
            border-color: ${theme.primary};
        }
    }

    /* MODAL CLOSE BUTTON */
    .close {
        &:focus {
            outline: 0 !important;
        }
    }

    .btn, button {
        &:focus{
            box-shadow: none !important;
        }
    }

    .noMarker{
        list-style-type: none;
    }

    .noIndent{
        padding-left: 0;
    }

    p, li{
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .vmc-treasures {
        color: #656164;
    }

    .vmc-ministry {
        color: #a56803;
    }

    .vmc-christian-living {
        color: #99131e;
    }

    .vmc-primary {
        color: #4a6da7;
    }

    .program-view{
        .row{
            padding: 20px 0;
        }

        .col-md-9{
            padding-right: 64px;

            @media(max-width: 768px){
                padding-right: 12px;
            }
        }
    }

    .badge-vmc{
        background-color: gray;
        color: white;
        display: inline-block;
        font-weight: bold;
        font-style: italic;
        padding: 5px 20px;
        border-radius: 4px;
    }

    .badge-vmc-small{
        background-color: gray;
        color: white;
        display: inline-block;
        font-weight: bold;
        padding: 2px 10px;
        font-size: 12px;
        border-radius: 4px;
    }

    .assigned{
        color: gray;
        font-weight: bold;
        font-style: italic;

        @media(max-width: 768px){
            padding-left: 20px;
        }
    }

    .assigned-aux{
        color: gray;
        font-style: italic;

        @media(max-width: 768px){
            padding-left: 20px;
        }
    }

    ul{
        padding-bottom: 0;
    }

    .chairman-title{
        color: gray;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
    }

    .table > :not(:last-child) > :last-child > * {
        border-bottom-color: #4a6da7;
    }

    .fa-male{
        color: #4a6da7;
    }

    .fa-female{
        color: #bd0a00;
    }

    .fa-check {
        color: green;
    }

    .red{
        color: #bd0a00;
    }

    .green {
        color: green;
    }

    .fa-times {
        color: #bd0a00;
    }

    .fa-minus {
        color: #bd0a00;
    }

    .dropdown-item.active, .dropdown-item:active {
        background-color: lightgray;
    }

    select {
        appearance: menulist !important;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .bold{
        font-weight: bold;
    }

    .selection-row{
        color: white;
        font-weight: 700;
        background-color: #4a6da7;
    }

    .month-picker{
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid gray;
        text-align: center;

        &:focus{
            outline: 0;
        }
    }

    button.close{
        border: none;
        background: none;
        font-size: 20px;
    }

    .pl-1 {
        padding-left: 0.25rem;
    }

    .pr-1 {
        padding-right: 0.25rem;
    }

    .pl-2 {
        padding-left: 0.5rem;
    }

    .pr-2 {
        padding-right: 0.5rem;
    }

    .pl-3 {
        padding-left: 1rem;
    }

    .pr-3 {
        padding-right: 1rem;
    }

    .pl-4 {
        padding-left: 1.5rem;
    }

    .pr-4 {
        padding-right: 1.5rem;
    }

    .react-bootstrap-table{
        .sr-only{
            display: none;
        }
    }

    .pretty.p-switch.p-fill input:checked ~ .state label:after {
        top: -2px;
    }

    .pretty.p-switch .state label:after {
        top: -2px;
    }
`;

export default GlobalStyles;

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import useCongregationId from './useCongregationId';

export default function useEstudiantes(): UseQueryResult {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
        query {
            estudiantes(congregacion_id: ${congregationId}) {
                id
                nombres
                apellidos
                sexo
                privilegio
                grupo
                inactivo
                tipo
            }
        }
      `);
        return response.estudiantes;
    };
    return useQuery('allEstudiantes', loadData);
}

import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Eventos } from 'services/constants';
import { getMonday } from 'services/utils';
import { useMutation, useQueryClient } from 'react-query';
import createEvento from 'hooks/createEvento';
import useCongregationId from 'hooks/useCongregationId';
import IEvento from 'types/IEvento';
import styled from 'styled-components';
import Input from './Input';
import MainContext from 'components/layout/MainContext';
import { useTranslation } from 'react-i18next';

export default function ModalCreateNota (): JSX.Element {
    const { t } = useTranslation();
    const { selectedDate } = React.useContext(MainContext);
    const [observations, setObservations] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(!modal);
    const queryClient = useQueryClient();
    const congregationId = useCongregationId();

    const mutationCreate = useMutation(createEvento, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            console.log('ERROR');
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEventos');
            setIsSubmitting(false);
            setObservations('');
            setModal(false);
        },
    });

    const handleSubmit = () => {
        if (!observations.trim().length){
            return;
        }
        if (congregationId){
            const newData: IEvento = {
                id: -1,
                fecha: getMonday(selectedDate),
                tipo: Eventos.NOTA,
                congregacion_id: congregationId,
                observaciones: observations,
            };
            // console.log('ENVIO', newData);
            mutationCreate.mutate(newData);
        }
    };

    return (
        <div>
            <AddButton onClick={toggleModal} title="Agrega observación"><i className="far fa-sticky-note" /></AddButton>
            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} centered>
                <ModalHeader toggle={toggleModal}>{t('note.create-new', 'Crear observación')}</ModalHeader>
                {modal && (
                    <ModalBody className="p-4">
                        <Input
                            name="observaciones"
                            label={t('note.note', 'Nota')}
                            onChange={(value) => setObservations(value)}
                        />
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button onClick={handleSubmit} color="primary" type="button" disabled={isSubmitting}>
                        {t('event.create', 'Crear')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const AddButton = styled.button`
    background: none;
    border: none;
    color: #4a6da7;
    font-size: 30px;
    padding: 0;
    margin: 0;
`;

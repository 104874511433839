import * as React from 'react';
import Box from 'components/layout/Box';
import Table from 'components/general/Table';
import ResponsiveButton from 'components/general/ResponsiveButton';
import useCongregations from 'hooks/useCongregations';
import ICongregation from 'types/ICongregation';
import { useTranslation } from 'react-i18next';

export default function Congregations (): JSX.Element {
    const { data: estudiantes, isLoading } = useCongregations();
    const data = estudiantes as ICongregation[];
    const { t } = useTranslation();

    const columns = React.useMemo(() => [
        {
            text: t('congregation.name', 'Nombre'),
            dataField: 'nombre',
        },
        {
            text: t('congregation.country', 'País'),
            dataField: 'pais',
            headerStyle: () => ({ width: '150px' }),
        },
    ], []);

    return (
        <Box title={t('menu.congregations', 'Congregaciones')}>
            <ResponsiveButton icon="fas fa-plus" disabled />
            <Table data={data} columns={columns} loading={isLoading} noPaginate />
        </Box>
    );
}

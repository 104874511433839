import Box from 'components/layout/Box';
import { Row, Col } from 'reactstrap';
import SettingsSalas from 'components/forms/SettingsSalas';
import SettingsGroups from 'components/forms/SettingsGroups';
import SettingsEventos from 'components/forms/SettingsEventos';
import { useTranslation } from 'react-i18next';
import SettingsLanguage from 'components/forms/SettingsLanguage';

export default function Settings (): JSX.Element {
    const { t } = useTranslation();

    return (
        <Box title={t('menu.settings', 'Configuración')}>
            <Row>
                <Col md="6">
                    <SettingsSalas />
                </Col>
                <Col md="6">
                    <SettingsGroups />
                </Col>
                <Col md="6">
                    <SettingsEventos />
                </Col>
                <Col md="6">
                    <SettingsLanguage />
                </Col>
            </Row>
        </Box>
    );
}

import * as React from 'react';
import { Redirect } from 'react-router';
import API from 'services/api';
import MainContext from 'components/layout/MainContext';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import { Button, Alert } from 'reactstrap';
import Input from 'components/general/Input';
import Loader from 'react-spinners/PulseLoader';
import { useTranslation } from 'react-i18next';

export default function LoginForm(): JSX.Element {
    const { initSession } = React.useContext(MainContext);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const isSessionOpen = useIsSessionOpen();
    const [redirect, setRedirect] = React.useState(isSessionOpen);
    const [loginError, setLoginError] = React.useState(false);
    const { t } = useTranslation();

    const handleSubmit = async (event: React.FormEvent) => {
        setIsSubmitting(true);
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const data = {
            email: (formData.get('email') as string).trim(),
            password: formData.get('password') as string,
        };
        try{
            const response = await API.login(data);
            if (response?.data?.token){
                setIsSubmitting(false);
                setRedirect(true);
                initSession(response.data);
            }
        }catch(error){
            setIsSubmitting(false);
            if (error.response.status === 403){
                setLoginError(true);
            }else{
                console.log('error', error);
            }
        }
    };

    if (redirect) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <form onSubmit={handleSubmit} className="mt-4">
            <Input name="email" label={t('login.email', 'Correo electrónico')} onChange={() => setLoginError(false)} defaultValue="" className="pb-2" />
            <Input name="password" label={t('login.password', 'Contraseña')} type="password" onChange={() => setLoginError(false)} defaultValue="" className="pb-2" />

            <div className="d-flex justify-content-end align-items-center">
                {isSubmitting && (
                    <span className="mx-3">
                        <Loader color="#4a6da7" size={10}/>
                    </span>
                )}
                <Button color="primary" disabled={isSubmitting}>
                    {t('general.login', 'Entrar')}
                </Button>
            </div>
            {loginError && <Alert color="danger" className="mt-4 mb-0">{t('error.wrong-credentials', 'Credenciales incorrectas')}</Alert>}
        </form>
    );
}


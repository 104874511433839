import * as React from 'react';
import { ModalBody, ModalFooter, Row, Col, Form, Button } from 'reactstrap';
import IUser from 'types/IUser';
import Input from 'components/general/Input';
import Select from 'components/general/Select';
import Loader from 'react-spinners/PulseLoader';
import { useMutation, useQueryClient } from 'react-query';
import updateUser from 'hooks/updateUser';
import createUser from 'hooks/createUser';
import useCongregationId from 'hooks/useCongregationId';
import { Roles, RolesOptions } from 'services/constants';
import useCurrentUser from 'hooks/useCurrentUser';
import useCongregations from 'hooks/useCongregations';
import ICongregation from 'types/ICongregation';
import useIsRole from 'hooks/useIsRole';
import { useTranslation } from 'react-i18next';

interface Props {
    data: IUser | null,
    onFinish: () => void,
}

const initialData: IUser = {
    id: -1,
    name: '',
    lastname: '',
    email: '',
    password: '',
    congregacion_id: -1,
    rol: 10,
    primera_vez: 1,
};

export default function UserForm ({ data: user, onFinish }: Props): JSX.Element {
    const {data: userData} = useCurrentUser();
    const { t } = useTranslation();

    const getRolesToAssign = (currentUserRole: number) => {
        return RolesOptions(t).filter((item) => item.value <= currentUserRole).map((item) => ({ value: item.value.toString(), label: item.label }));
    };

    const currentUser = userData as IUser;
    const optionsRole = getRolesToAssign(currentUser.rol);
    const data = user || initialData;
    const [trySubmit, setTrySubmit] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [names, setNames] = React.useState(data?.name || '');
    const [lastnames, setLastname] = React.useState(data?.lastname || '');
    const [email, setEmail] = React.useState(data?.email || '');
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const {data: congregationsData} = useCongregations();
    const congregations = congregationsData as ICongregation[];
    const optionsCongregation = congregations?.map((item) => ({value: item.id, label: item.nombre})) || [];
    const congregationId = useCongregationId();

    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const queryClient = useQueryClient();

    const mutationUpdate = useMutation(updateUser, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allUsers');
            queryClient.invalidateQueries(['User', data.id]);
            setIsSubmitting(false);
            onFinish();
        },
    });

    const mutationCreate = useMutation(createUser, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allUsers');
            setIsSubmitting(false);
            onFinish();
        },
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!!names.trim() && !!lastnames.trim() && !!email.trim() && password.trim().length >= 3 && password === repeatPassword && congregationId){
            const formData = new FormData(event.target as HTMLFormElement);
            const newData: IUser = {
                id: data.id,
                name: formData.get('name') as string,
                lastname: formData.get('lastname') as string,
                email: formData.get('email') as string,
                password: formData.get('password') as string,
                rol: parseInt(formData.get('rol') as string),
                congregacion_id: isAdmin ? parseInt(formData.get('congregation') as string) : congregationId,
                primera_vez: data.primera_vez,
            };

            if (data.id === -1) {
                mutationCreate.mutate(newData);
            }else{
                mutationUpdate.mutate(newData);
            }
        } else {
            setTrySubmit(true);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody>
                <Row>
                    <Col md="6"><Input name="name" label={t('person.name', 'Nombres')} defaultValue={data.name} invalid={trySubmit && names.trim() === ''} onChange={(value) => setNames(value)} /></Col>
                    <Col md="6"><Input name="lastname" label={t('person.lastname', 'Apellidos')} defaultValue={data.lastname} invalid={trySubmit && lastnames.trim() === ''} onChange={(value) => setLastname(value)} /></Col>
                    <Col md="6"><Input name="email" type="email" label={t('person.email', 'Correo electrónico')} defaultValue={data.email} invalid={trySubmit && email.trim() === ''} onChange={(value) => setEmail(value)} /></Col>
                    <Col md="6"><Select name="rol" label={t('person.role', 'Rol')} options={optionsRole} /></Col>
                    {isAdmin && (
                        <Col md="12"><Select name="congregation" label={t('person.congregation', 'Congregación')} options={optionsCongregation} /></Col>
                    )}
                    <Col md="6"><Input name="password" type="password" label={t('person.password', 'Contraseña')} invalid={trySubmit && password.trim().length < 3} onChange={(value) => setPassword(value)} /></Col>
                    <Col md="6"><Input name="repeatPassword" type="password" label={t('person.repeat-password', 'Repetir contraseña')} invalid={trySubmit && password !== repeatPassword} onChange={(value) => setRepeatPassword(value)} /></Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end align-items-center">
                    {isSubmitting && (
                        <span className="mx-3">
                            <Loader color="#4a6da7" size={10}/>
                        </span>
                    )}
                    <Button color="primary" disabled={isSubmitting}>
                        {t('general.save', 'Guardar')}
                    </Button>
                </div>
            </ModalFooter>
        </Form>
    );
}

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import ISala from 'types/ISala';
import useCongregationId from 'hooks/useCongregationId';

const useSalas = (): UseQueryResult => {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                salas(congregacion_id: ${congregationId}){
                    id
                    nombre
                    activa
                    congregacion_id
                    numero
                }
            }
        `);
        return response.salas;
    };

    return useQuery<ISala[], Error>('allSalas', loadData);
};

export default useSalas;

import useCurrentUser from 'hooks/useCurrentUser';
import IUser from 'types/IUser';

const useIsRole = (role: number): boolean => {
    const {data: userData} = useCurrentUser();
    const currentUser = userData as IUser;

    if (!currentUser) return false;

    return role === currentUser.rol;
};

export default useIsRole;

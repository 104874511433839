/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import IAssignment from 'types/IAssignment';
import ISala from 'types/ISala';
import ISelectOption from 'types/ISelectOption';
import { RolesOptions, Eventos, Kind, Sections } from './constants';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { startOfWeek, endOfWeek, format, getDay, add, startOfMonth } from 'date-fns';
import iconTreasures from 'assets/iconTreasures.png';
import iconMinistry from 'assets/iconMinistry.png';
import iconChristianLiving from 'assets/iconChristianLiving.png';
import iconGeneral from 'assets/iconGeneral.jpg';
import IProgram from 'types/IProgram';

export function findAssignedName(type: number, assignments: IAssignment[]): string | null{
    const assignment = assignments?.find((item: IAssignment) => item.tipo === type);
    if (assignment?.estudiante){
        return `${assignment.estudiante.nombres} ${assignment.estudiante.apellidos}`;
    }
    return null;
}

export function findAssignedId(type: number, assignments: IAssignment[]): number | null{
    const assignment = assignments.find((item: IAssignment) => item.tipo === type);
    if (assignment?.estudiante){
        return assignment.estudiante.id;
    }
    return null;
}

export function findAssignmentId(type: number, assignments: IAssignment[]): number | null{
    const assignment = assignments?.find((item: IAssignment) => item.tipo === type);
    if (assignment){
        return assignment.id;
    }
    return null;
}

export function isSalaActive(id: number, assignments: ISala[]): boolean{
    const sala = assignments?.find((item: ISala) => item.numero === id);
    if (sala){
        return sala.activa;
    }
    return false;
}

export function getSalaName(id: number, assignments: ISala[]): string{
    const sala = assignments?.find((item: ISala) => item.numero === id);
    if (sala){
        return sala.nombre;
    }
    return 'Parte X, Sala X';
}

export function getGroupsOptions (max: number, addEmpty = true): ISelectOption[] {
    const array:ISelectOption[] = [];
    if (addEmpty){
        array.push({value: '', label: '' });
    }

    for (let ii = 1; ii <= max; ii++){
        const vv = ii.toString();
        array.push({value: vv, label: vv });
    }

    return array;
}

export function getRoleName(role: number, t: any): string {
    const item = RolesOptions(t).find((item) => item.value === role);
    if (item) {
        return item.label;
    }
    return '';
}

export const titleCase = (str: string): string => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
};

export const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const getEventoText = (type: number, t: any): string => {
    switch (type) {
    case Eventos.VISITA_CIRCUITO: return t('event.circuit-visit', 'Visita del Superintendente de Circuito');
    case Eventos.ASAMBLEA_CIRCUITO_SUP: return t('event.circuit-assembly', 'Asamblea de Circuito con Superintendente de Circuito');
    case Eventos.ASAMBLEA_CIRCUITO_SUC: return t('event.circuit-assembly-branch', 'Asamblea de Circuito con Representante de la Sucursal');
    case Eventos.ASAMBLEA_REGIONAL: return t('event.regional-convention', 'Asamblea Regional');
    case Eventos.OTRO: return t('event.other', 'Otro (Reunión cancelada)');
    default: return '';
    }
};

export const getSection = (type: number): number => {
    switch (type) {
    case Kind.PRESIDENCIA:
    case Kind.ORACION_INICIO:
    case Kind.ORACION_FINAL:
    case Kind.ENCARGADO_SALA_2:
    case Kind.ENCARGADO_SALA_3: return Sections.GENERAL;

    case Kind.DISCURSO:
    case Kind.PERLAS:
    case Kind.LECTURA_SALA_1:
    case Kind.LECTURA_SALA_2:
    case Kind.LECTURA_SALA_3: return Sections.TREASURES;

    case Kind.NVC_1:
    case Kind.NVC_2:
    case Kind.NVC_3:
    case Kind.CONDUCTOR_ESTUDIO_BIBLICO:
    case Kind.LECTOR_ESTUDIO_BIBLICO: return Sections.CHRISTIAN_LIVING;

    default: return Sections.MINISTRY;
    }
};

export const getIsEstudiantil = (type: number): boolean => {
    if (getSection(type) === Sections.MINISTRY) return true;
    if (type === Kind.LECTURA_SALA_1 || type === Kind.LECTURA_SALA_2 || type === Kind.LECTURA_SALA_3) return true;
    return false;
};

export const getSectionIcon = (type: number): string => {
    const section = getSection(type);
    switch (section){
    case Sections.TREASURES: return iconTreasures;
    case Sections.MINISTRY: return iconMinistry;
    case Sections.CHRISTIAN_LIVING: return iconChristianLiving;
    default: return iconGeneral;
    }
};

export const getDateRangeFormatted = (date: Date, locale = 'es-US', short = false): string => {
    const dateFormat = short ? 'd \'de\' MMM' : 'd \'de\' MMMM';
    const dateFormatEn = short ? 'MMM, d' : 'MMMM, d';
    const monday = startOfWeek(getUTC(date), { weekStartsOn: 1 });
    const sunday = endOfWeek(getUTC(date), { weekStartsOn: 1 });
    return `${format(monday, locale === 'es-US' ? dateFormat : dateFormatEn, { locale: locale === 'es-US' ? es : en })} ${locale === 'es-US' ? 'a' : 'to'} ${format(sunday, locale === 'es-US' ? dateFormat : dateFormatEn, { locale: locale === 'es-US' ? es : en })}`;
};

export const getUTC = (date: Date): Date => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const escapeHtml = (text: string ): string => {
    // eslint-disable-next-line no-control-regex
    return (text + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0').replace(/(\r\n|\n|\r)/gm, '').trim();
};

export const getMonday = (date: Date): string => {
    return format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy/MM/dd');
};

export const groupBy = (key: string) => (array: any[]): any => array?.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    // eslint-disable-next-line no-param-reassign
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
}, {});

export const getPartText = (t: any, type: number, program?: IProgram, long = false): string => {
    if (!program) return '';

    const sala2Name = t('report.aux-room-1', ' - Sala aux núm. 1');
    const sala3Name = t('report.aux-room-2', ' - Sala aux núm. 2');

    switch(type){
    case Kind.PRESIDENCIA: return t('report.chairman', 'Presidencia');
    case Kind.ORACION_INICIO: return t('report.initial-prayer', 'Oración de inicio');
    case Kind.ENCARGADO_SALA_2: return t('report.in-charge', 'Encargado {{sala}}', { sala: sala2Name });
    case Kind.ENCARGADO_SALA_3: return t('report.in-charge', 'Encargado {{sala}}', { sala: sala3Name });
    case Kind.ORACION_FINAL: return t('report.ending-prayer', 'Oración final');

    case Kind.DISCURSO: return program.discurso;
    case Kind.PERLAS: return `${t('report.gems', 'Perlas')}: ${program.lectura_semanal}`;

    case Kind.LECTURA_SALA_1: return `${t('report.reading', 'Lectura')}: ${program.lectura}`;
    case Kind.LECTURA_SALA_2: return `${t('report.reading', 'Lectura')}: ${program.lectura}${sala2Name}`;
    case Kind.LECTURA_SALA_3: return `${t('report.reading', 'Lectura')}: ${program.lectura}${sala3Name}`;

    case Kind.PARTE_1_SALA_1: return `${program.smm1} ${long ? `(${program.smm1_duracion} mins.)<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_2_SALA_1: return `${program.smm2} ${long ? `(${program.smm2_duracion} mins.)<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_3_SALA_1: return `${program.smm3} ${long ? `(${program.smm3_duracion} mins.)<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_4_SALA_1: return `${program.smm4} ${long ? `(${program.smm4_duracion} mins.)<br/><small>${program.smm4_fuente}</small>` : ''}`;
    case Kind.PARTE_1_SALA_2: return `${program.smm1} ${long ? `(${program.smm1_duracion} mins.)${sala2Name}<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_1_SALA_3: return `${program.smm1} ${long ? `(${program.smm1_duracion} mins.)${sala3Name}<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_2_SALA_2: return `${program.smm2} ${long ? `(${program.smm2_duracion} mins.)${sala2Name}<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_2_SALA_3: return `${program.smm2} ${long ? `(${program.smm2_duracion} mins.)${sala3Name}<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_3_SALA_2: return `${program.smm3} ${long ? `(${program.smm3_duracion} mins.)${sala2Name}<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_3_SALA_3: return `${program.smm3} ${long ? `(${program.smm3_duracion} mins.)${sala3Name}<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_4_SALA_2: return `${program.smm4} ${long ? `(${program.smm4_duracion} mins.)${sala2Name}<br/><small>${program.smm4_fuente}</small>` : ''}`;
    case Kind.PARTE_4_SALA_3: return `${program.smm4} ${long ? `(${program.smm4_duracion} mins.)${sala3Name}<br/><small>${program.smm4_fuente}</small>` : ''}`;

    case Kind.PARTE_1_AYUDANTE_SALA_1: return `${t('report.helper', 'Ayudante')}: ${program.smm1} ${long ? `(${program.smm1_duracion} mins.)<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_2_AYUDANTE_SALA_1: return `${t('report.helper', 'Ayudante')}: ${program.smm2} ${long ? `(${program.smm2_duracion} mins.)<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_3_AYUDANTE_SALA_1: return `${t('report.helper', 'Ayudante')}: ${program.smm3} ${long ? `(${program.smm3_duracion} mins.)<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_4_AYUDANTE_SALA_1: return `${t('report.helper', 'Ayudante')}: ${program.smm4} ${long ? `(${program.smm4_duracion} mins.)<br/><small>${program.smm4_fuente}</small>` : ''}`;
    case Kind.PARTE_1_AYUDANTE_SALA_2: return `${t('report.helper', 'Ayudante')}: ${program.smm1} ${long ? `(${program.smm1_duracion} mins.) ${sala2Name}<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_1_AYUDANTE_SALA_3: return `${t('report.helper', 'Ayudante')}: ${program.smm1} ${long ? `(${program.smm1_duracion} mins.) ${sala3Name}<br/><small>${program.smm1_fuente}</small>` : ''}`;
    case Kind.PARTE_2_AYUDANTE_SALA_2: return `${t('report.helper', 'Ayudante')}: ${program.smm2} ${long ? `(${program.smm2_duracion} mins.) ${sala2Name}<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_2_AYUDANTE_SALA_3: return `${t('report.helper', 'Ayudante')}: ${program.smm2} ${long ? `(${program.smm2_duracion} mins.) ${sala3Name}<br/><small>${program.smm2_fuente}</small>` : ''}`;
    case Kind.PARTE_3_AYUDANTE_SALA_2: return `${t('report.helper', 'Ayudante')}: ${program.smm3} ${long ? `(${program.smm3_duracion} mins.) ${sala2Name}<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_3_AYUDANTE_SALA_3: return `${t('report.helper', 'Ayudante')}: ${program.smm3} ${long ? `(${program.smm3_duracion} mins.) ${sala3Name}<br/><small>${program.smm3_fuente}</small>` : ''}`;
    case Kind.PARTE_4_AYUDANTE_SALA_2: return `${t('report.helper', 'Ayudante')}: ${program.smm4} ${long ? `(${program.smm4_duracion} mins.) ${sala2Name}<br/><small>${program.smm4_fuente}</small>` : ''}`;
    case Kind.PARTE_4_AYUDANTE_SALA_3: return `${t('report.helper', 'Ayudante')}: ${program.smm4} ${long ? `(${program.smm4_duracion} mins.) ${sala3Name}<br/><small>${program.smm4_fuente}</small>` : ''}`;

    case Kind.NVC_1: return program.nvc1;
    case Kind.NVC_2: return program.nvc2;
    case Kind.NVC_3: return program.nvc3;
    case Kind.CONDUCTOR_ESTUDIO_BIBLICO: return `${t('report.conductor', 'Conductor')}: ${program.estudio_biblico}`;
    case Kind.LECTOR_ESTUDIO_BIBLICO: return `${t('report.reader', 'Lector')}: ${program.estudio_biblico}`;
    default: return '';
    }
};

export const clearHtml = (value: string): string => value?.replace(/<(?:.|\n)*?>/gm, '');

export const getFirstMonday = (date: Date): Date => {

    const firstDay = startOfMonth(date);

    const weekDay = getDay(firstDay);

    switch (weekDay){
    case 2: return add(firstDay, { days: 6 });
    case 3: return add(firstDay, { days: 5 });
    case 4: return add(firstDay, { days: 4 });
    case 5: return add(firstDay, { days: 3 });
    case 6: return add(firstDay, { days: 2 });
    case 0: return add(firstDay, { days: 1 });
    default: return firstDay;
    }
};

import * as React from 'react';
import { ModalBody, ModalFooter, Row, Col, Form, Button } from 'reactstrap';
import IEstudiante from 'types/IEstudiante';
import Input from 'components/general/Input';
import Select from 'components/general/Select';
import { getGroupsOptions } from 'services/utils';
import Loader from 'react-spinners/PulseLoader';
import { PrivilegiosOptions, TiposOptions } from 'services/constants';
import { useMutation, useQueryClient } from 'react-query';
import updateEstudiante from 'hooks/updateEstudiante';
import createEstudiante from 'hooks/createEstudiante';
import useCongregationId from 'hooks/useCongregationId';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import Capacities from 'components/forms/Capacities';
import useNumberGroups from 'hooks/useNumberGroups';
import { useTranslation } from 'react-i18next';

interface Props {
    data: IEstudiante | null,
    onFinish: () => void,
}

const initialData: IEstudiante = {
    id: -1,
    nombres: '',
    apellidos: '',
    sexo: '',
    fecha_nacimiento: '',
    fecha_bautizado: '',
    fecha_matriculado: '',
    telefono: '',
    email: '',
    observaciones: '',
    inactivo: 0,
    grupo: '',
    direccion1: '',
    direccion2: '',
    tipo: '',
    privilegio: '',
    congregacion_id: -1,
    capacidades: '',
};


export default function EstudianteForm ({ data: estudiante, onFinish }: Props): JSX.Element {
    const [activeTab, setActiveTab] = React.useState('1');
    const { t } = useTranslation();

    const data = estudiante || initialData;
    const [trySubmit, setTrySubmit] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [gender, setGender ] = React.useState(data?.sexo || '');
    const [type, setType] = React.useState(data?.tipo || '');
    const [names, setNames] = React.useState(data?.nombres || '');
    const [lastnames, setLastnames] = React.useState(data?.apellidos || '');
    const [capacities, setCapacities] = React.useState(data?.capacidades || '');

    const numGroups = useNumberGroups();

    const congregationId = useCongregationId();

    const queryClient = useQueryClient();

    const toggle = (tab: string) => setActiveTab(tab);

    const mutationUpdate = useMutation(updateEstudiante, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEstudiantes');
            queryClient.invalidateQueries(['Estudiante', data.id]);
            setIsSubmitting(false);
            onFinish();
        },
    });

    const mutationCreate = useMutation(createEstudiante, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEstudiantes');
            setIsSubmitting(false);
            onFinish();
        },
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!!names.trim() && !!lastnames.trim() && !!gender && congregationId && !!type.trim()){
            const formData = new FormData(event.target as HTMLFormElement);
            const newData: IEstudiante = {
                id: data.id,
                inactivo: ((formData.get('inactivo') as string) !== 'on') ? 1 : 0,
                nombres: formData.get('nombres') as string,
                apellidos: formData.get('apellidos') as string,
                sexo: formData.get('sexo') as string,
                fecha_nacimiento: (formData.get('fecha_nacimiento') as string)?.replace(/-/g, '/') || '',
                fecha_bautizado: (formData.get('fecha_bautizado') as string)?.replace(/-/g, '/') || '',
                fecha_matriculado: (formData.get('fecha_matriculado') as string)?.replace(/-/g, '/') || '',
                telefono: formData.get('telefono') as string,
                email: formData.get('email') as string,
                observaciones: formData.get('observaciones') as string,
                grupo: formData.get('grupo') as string,
                direccion1: formData.get('direccion1') as string,
                direccion2: formData.get('direccion2') as string,
                tipo: formData.get('tipo') as string,
                privilegio: formData.get('privilegio') as string || '',
                congregacion_id: congregationId,
                capacidades: capacities,
            };
            if (data.id === -1) {
                mutationCreate.mutate(newData);
            }else{
                mutationUpdate.mutate(newData);
            }
        } else {
            setTrySubmit(true);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody className="p-0">
                <NavStyled tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                            <small>{t('person.personal-info', 'Datos personales')}</small>
                        </NavLink>
                    </NavItem>
                    {gender && (
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                <small>{t('person.capacities', 'Capacidades')}</small>
                            </NavLink>
                        </NavItem>
                    )}
                </NavStyled>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="border border-top-0 p-4">
                        <Row>
                            <Col md="12" className="d-flex justify-content-end">
                                <div className="pretty p-switch p-fill">
                                    <input type="checkbox" name="inactivo" id="inactivo" defaultChecked={!data.inactivo} />
                                    <div className="state"><label>{t('person.available', 'Disponible')}</label></div>
                                </div>
                            </Col>
                            <Col md="6"><Input name="nombres" label={t('person.name', 'Nombres')} defaultValue={data.nombres} invalid={trySubmit && names.trim() === ''} onChange={(value) => setNames(value)} /></Col>
                            <Col md="6"><Input name="apellidos" label={t('person.lastname', 'Apellidos')} defaultValue={data.apellidos} invalid={trySubmit && lastnames.trim() === ''} onChange={(value) => setLastnames(value)} /></Col>
                            <Col md="3">
                                <Select
                                    name="sexo"
                                    label="Sexo"
                                    defaultValue={data.sexo}
                                    options={[ {value: '', label: ''}, {value: 'Masculino', label: t('person.male', 'Masculino')}, {value: 'Femenino', label: t('person.female', 'Femenino')} ]}
                                    onChange={(value) => {setGender(value.toString());}}
                                    invalid={trySubmit && gender === ''}
                                />
                            </Col>
                            <Col md="3"><Input name="fecha_nacimiento" label={t('person.birth-date', 'Fecha de nacimiento')} type="date" defaultValue={data.fecha_nacimiento} /></Col>
                            <Col md="3"><Input name="fecha_matriculado" label={t('person.enrollment-date', 'Fecha de matriculado')} type="date" defaultValue={data.fecha_matriculado} /></Col>
                            <Col md="3"><Input name="telefono" label={t('person.phone', 'Teléfono')} defaultValue={data.telefono} /></Col>
                            <Col md="9"><Input name="email" type="email" label={t('person.email', 'Correo electrónico')} defaultValue={data.email} /></Col>
                            <Col md="3"><Select name="grupo" label={t('person.group', 'Grupo')} defaultValue={data.grupo} options={getGroupsOptions(numGroups)} /></Col>
                        </Row>
                        <Row>
                            <Col md="6"><Select name="tipo" label={t('person.type', 'Tipo')} defaultValue={data.tipo} options={[{value: '', label: ''}, ...TiposOptions(t)]} invalid={trySubmit && type === ''} onChange={(value) => {setType(value.toString());}}/></Col>
                            {(type === 'Precursor' || type === 'Publicador bautizado' ) && (
                                <Col md="6"><Input name="fecha_bautizado" label={t('person.baptism-date', 'Fecha de bautismo')} type="date" defaultValue={data.fecha_bautizado} /></Col>
                            )}
                        </Row>
                        <Row>
                            {gender === 'Masculino' && (type === 'Precursor' || type === 'Publicador bautizado' ) && (
                                <Col md="6"><Select name="privilegio" label={t('person.privilege', 'Privilegio')} defaultValue={data.privilegio} options={[{value: '', label: ''}, ...PrivilegiosOptions(t)]} /></Col>
                            )}
                            <Col md="12"><Input name="direccion1" label={t('person.address', 'Dirección')} defaultValue={data.direccion1} /></Col>
                            <Col md="12"><Input name="direccion2" label={t('person.address-emergency', 'Dirección en caso de Emergencias')} defaultValue={data.direccion2} /></Col>
                            <Col md="12"><Input name="observaciones" type="textarea" label={t('person.observations', 'Observaciones')} defaultValue={data.observaciones} /></Col>
                        </Row>                    </TabPane>
                    <TabPane tabId="2" className="border border-top-0">
                        <Capacities data={data.capacidades} isMale={gender === 'Masculino'} onChange={(value) => { setCapacities (value); }} />
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end align-items-center">
                    {isSubmitting && (
                        <span className="mx-3">
                            <Loader color="#4a6da7" size={10}/>
                        </span>
                    )}
                    <Button color="primary" type="button" onClick={onFinish} outline disabled={isSubmitting} className="mx-3">
                        {t('general.cancel', 'Cancelar')}
                    </Button>
                    <Button color="primary" disabled={isSubmitting}>
                        {t('general.save', 'Guardar')}
                    </Button>
                </div>
            </ModalFooter>
        </Form>
    );
}

const NavStyled = styled(Nav)`
    li{
        margin: 0;
    }

    .nav-link{
        color: gray;
    }
`;

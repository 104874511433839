import { Card } from 'reactstrap';
import styled from 'styled-components';
import MainTitle from 'components/layout/LoginLogo';
import { Redirect } from 'react-router-dom';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import LinkBack from 'components/general/LinkBack';
import Footer from 'components/layout/Footer';

interface Props {
    children: React.ReactNode;
}

const Auth = ({ children }: Props): JSX.Element => {
    const isSessionOpen = useIsSessionOpen();

    if (isSessionOpen) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            <AuthPageContainer>
                <ContainerStyled>
                    <AuthBox>
                        <TitlePosition><MainTitle /></TitlePosition>
                        {children}
                    </AuthBox>
                    <LinkBack />
                </ContainerStyled>
            </AuthPageContainer>
            <Footer />
        </>
    );
};

export default Auth;

const AuthBox = styled(Card)`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
    margin-top: 80px;
    padding: 30px;

    @media (max-width: 576px) {
        margin-bottom: 40px;
        margin-top: 60px;
        padding: 30px 20px;
    }
`;

const TitlePosition = styled.div`
    margin-top: -65px;
`;

const ContainerStyled = styled.div`
    height: 100%;
    max-width: 400px;
    width: 90%;
    padding: 50px 0;
`;

const AuthPageContainer = styled.div`
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.mainBackground};
    display: flex;
    min-height: calc(100vh - 50px);

    @media (max-width: 576px) {
        min-height: calc(100vh - 60px);
    }
`;

import * as React from 'react';
import MainContext from 'components/layout/MainContext';
import { useLocation } from 'react-router-dom';

function useQueryStrings(key: string) {
    const query = new URLSearchParams(useLocation().search);
    return query.get(key);
}

export default function useCongregationId (): number | null {
    const congregationIdQS = useQueryStrings('cid');
    const { congregationId, storeCongregationId } = React.useContext(MainContext);
    let currentCongregationId: number | null;

    if (congregationIdQS) {
        const value = parseInt(congregationIdQS);
        storeCongregationId(value);
        currentCongregationId = value;
    }else{
        currentCongregationId = congregationId;
    }

    return currentCongregationId;
}


const SortCaret = (order: string): JSX.Element => {
    if (!order) {
        return (
            <span>
                <i className="fas fa-chevron-up" />
                <i className="fas fa-chevron-down" />
            </span>
        );
    }
    if (order === 'asc') {
        return (
            <span>
                <i className="fas fa-chevron-up" />
                <i className="fas fa-chevron-down text-primary" />
            </span>
        );
    }
    if (order === 'desc') {
        return (
            <span>
                <i className="fas fa-chevron-up text-primary" />
                <i className="fas fa-chevron-down" />
            </span>
        );
    }
    return <></>;
};

export default SortCaret;

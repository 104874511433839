import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import { Eventos } from 'services/constants';
import { getDateRangeFormatted, getEventoText, getMonday } from 'services/utils';
import Select from 'components/general/Select';
import ISelectOption from 'types/ISelectOption';
import { useMutation, useQueryClient } from 'react-query';
import createEvento from 'hooks/createEvento';
import useCongregationId from 'hooks/useCongregationId';
import IEvento from 'types/IEvento';
import styled from 'styled-components';
import Input from './Input';
import { useTranslation } from 'react-i18next';

export default function ModalCreateEvento (): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [date, setDate] = React.useState(new Date());
    const [type, setType] = React.useState(Eventos.VISITA_CIRCUITO);
    const [observations, setObservations] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(!modal);
    const queryClient = useQueryClient();
    const congregationId = useCongregationId();

    const EventosOptions: ISelectOption[] = [
        { value: Eventos.VISITA_CIRCUITO, label: getEventoText(Eventos.VISITA_CIRCUITO, t) },
        { value: Eventos.ASAMBLEA_CIRCUITO_SUP, label: getEventoText(Eventos.ASAMBLEA_CIRCUITO_SUP, t) },
        { value: Eventos.ASAMBLEA_CIRCUITO_SUC, label: getEventoText(Eventos.ASAMBLEA_CIRCUITO_SUC, t) },
        { value: Eventos.ASAMBLEA_REGIONAL, label: getEventoText(Eventos.ASAMBLEA_REGIONAL, t) },
        { value: Eventos.OTRO, label: getEventoText(Eventos.OTRO, t) },
    ];

    const mutationCreate = useMutation(createEvento, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEventos');
            setIsSubmitting(false);
            setDate(new Date());
            setType(Eventos.VISITA_CIRCUITO);
            setObservations('');
            setModal(false);
        },
    });

    const handleSubmit = () => {
        if (type === Eventos.OTRO && !observations.trim().length){
            return;
        }
        if (congregationId){
            const newData: IEvento = {
                id: -1,
                fecha: getMonday(date),
                tipo: type,
                congregacion_id: congregationId,
                observaciones: observations,
            };
            mutationCreate.mutate(newData);
        }
    };

    return (
        <div>
            <AddButton onClick={toggleModal}><i className="fas fa-plus-circle" /></AddButton>
            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} centered>
                <ModalHeader toggle={toggleModal}>{t('event.create-event', 'Crear evento')}</ModalHeader>
                {modal && (
                    <ModalBody className="p-4">
                        <div className="text-center mb-4">
                            <DatePicker
                                selected={date}
                                onChange={(date: Date) => setDate(date)}
                                locale={es}
                                inline
                                calendarStartDay={1}
                            />
                            {t('event.week-date', 'Semana del {{date}}', { date: getDateRangeFormatted(date, currentLanguage) })}
                        </div>
                        <Select
                            name={t('event.event', 'evento')}
                            label={t('event.type', 'Tipo')}
                            value={type}
                            onChange={(event => setType(parseInt(event.toString())))}
                            options={EventosOptions}
                        />
                        {type === Eventos.OTRO && (
                            <Input
                                name={t('event.observations', 'observaciones')}
                                label={t('event.reason', 'Motivo')}
                                onChange={(value) => setObservations(value)}
                            />
                        )}
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button onClick={handleSubmit} color="primary" type="button" disabled={isSubmitting}>
                        {t('general.create', 'Crear')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const AddButton = styled.button`
    background: none;
    border: none;
    color: #4a6da7;
    font-size: 30px;
    padding: 0;
    margin: 0;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input as InputRS, FormFeedback } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

interface Props {
    name: string,
    label: string,
    defaultValue?: string,
    type?: InputType
    className?: string,
    invalid?: boolean,
    onChange?: (value: string) => void,
    onFocus?: () => void,
    onBlur?: () => void,
}

export default function Input({ name, label, defaultValue, type, className, invalid, onChange, onFocus, onBlur }: Props): JSX.Element {
    const { t } = useTranslation();
    
    const handleChange = (event: React.SyntheticEvent) => {
        if (onChange){
            const target = event.target as HTMLInputElement;
            onChange(target.value);
        }
    };

    return (
        <FormGroup className={`mb-2 ${className}`}>
            <Label className="m-0" for={name}><small><b>{label}</b></small></Label>
            <InputRS
                name={name}
                id={name}
                defaultValue={defaultValue}
                rows="5"
                type={type}
                invalid={invalid}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <FormFeedback>{t('general.required-field', 'Campo requerido')}</FormFeedback>
        </FormGroup>
    );
}

import IProgram from 'types/IProgram';
import * as React from 'react';
import iconTreasures from 'assets/iconTreasures.png';
import { Col, Row } from 'reactstrap';
import SectionTitle from '../layout/SectionTitle';
import ISala from 'types/ISala';
import { getSalaName, isSalaActive } from 'services/utils';
import {Kind} from 'services/constants';
import useSalas from 'hooks/useSalas';
import Assignment from './Assignment';
import { useTranslation } from 'react-i18next';
interface Props {
    program: IProgram,
}

export default function SectionTreasures ({ program }: Props): JSX.Element {
    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];
    const { t } = useTranslation();

    if (!salas) return <></>;

    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const sala1Name = getSalaName(1, salas);
    const sala2Name = getSalaName(2, salas);
    const sala3Name = getSalaName(3, salas);
    const existExtraSalas = existSala2 || existSala3;

    return (
        <>
            <Row>
                <Col md="8"><h2><span dangerouslySetInnerHTML={{ __html: program.lectura_semanal }} /></h2></Col>
                <Col md="4">
                    <div style={{color: '#808080', fontSize: '12px', fontWeight: 'bold'}}>{t('program.chairman', 'PRESIDENCIA')}</div>
                    <Assignment type={Kind.PRESIDENCIA} />
                </Col>
            </Row>

            <Row>
                <Col md="8" className="mb-1"><strong>{t('program.song-prayer', 'Canción {{song}} y oración', { song: program.cancion1 })}</strong></Col>
                <Col md="4">
                    <Assignment type={Kind.ORACION_INICIO} />
                </Col>
            </Row>

            <p><strong>{t('program.opening-comments', 'Palabras de introducción')} </strong>(1 min.)</p>

            <section>
                <SectionTitle text={t('title.treasures', 'Tesoros de la biblia')} className="vmc-treasures" icon={iconTreasures} />
                <Row>
                    <Col md="8" className="mb-1"><strong dangerouslySetInnerHTML={{ __html: program.discurso }} /> (10 mins.)</Col>
                    <Col md="4">
                        <Assignment type={Kind.DISCURSO} />
                    </Col>
                </Row>
                <Row>
                    <Col md="8">
                        <strong>{t('program.spiritual-gems', 'Busquemos perlas escondidas')} </strong>(10 mins.)
                        <div dangerouslySetInnerHTML={{ __html: program.busquemos_perlas }} />
                    </Col>
                    <Col md="4">
                        <Assignment type={Kind.PERLAS} />
                    </Col>
                </Row>
                {existSala2 && (
                    <div className="mt-3">
                        <div className="badge-vmc-small mb-2">{sala2Name}</div>
                        <br/>
                        <span className="assigned-aux">{t('program.in-charge', 'Encargado')}: </span>
                        <Assignment type={Kind.ENCARGADO_SALA_2} />
                    </div>
                )}
                {existSala3 && (
                    <div className="my-3">
                        <div className="badge-vmc-small mb-2">{sala3Name}</div>
                        <br/>
                        <span className="assigned-aux">{t('program.in-charge', 'Encargado')}: </span>
                        <Assignment type={Kind.ENCARGADO_SALA_3} />
                    </div>
                )}
                <Row>
                    <Col md="8" className="mb-1"><strong>{t('program.bible-reading', 'Lectura de la Biblia')} </strong>(4 mins.): <span dangerouslySetInnerHTML={{ __html: program.lectura }} /></Col>
                    <Col md="4">
                        {existExtraSalas && <div className="badge-vmc-small mb-2">{sala1Name}</div>}
                        <Assignment type={Kind.LECTURA_SALA_1} />
                        {existSala2 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala2Name}</div>
                                <Assignment type={Kind.LECTURA_SALA_2} />
                            </>
                        )}
                        {existSala3 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala3Name}</div>
                                <Assignment type={Kind.LECTURA_SALA_3} />
                            </>
                        )}
                    </Col>
                </Row>
            </section>
        </>
    );
}

import styled from 'styled-components';

interface Props {
    text: string,
    icon: string,
    className?: string,
}

export default function SectionTitle({text, icon, className}: Props): JSX.Element {
    return (
        <Container className={`d-flex align-items-start ${className}`}>
            <Icon src={icon} alt="" />
            <H2>{text}</H2>
        </Container>
    );
}

const Container = styled.div`
    @media (max-width: 768px) {
        padding-top: 40px;
    }
`;

const H2 = styled.h2`
    text-transform: uppercase;
    line-height: 56px;
    padding-left: 20px;

    @media (max-width: 768px) {
        padding-left: 10px;
        font-size: 20px;
        line-height: 20px;
    }
`;

const Icon = styled.img`
    width: 56px;
    height: 56px;

    @media (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
`;

import useCongregation from 'hooks/useCongregation';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ICongregation from 'types/ICongregation';
import { useTranslation } from 'react-i18next';

const version = '1.4.1';

const Footer = (): JSX.Element => {
    const { t } = useTranslation();
    const isSessionOpen = useIsSessionOpen();
    const { data } = useCongregation();
    const currentCongregation = data as ICongregation;

    return (
        <FooterContainer>
            <div className="left">
                <a href="/">{t('footer.use-conditions', 'Condiciones de uso')}</a>
            </div>
            <div className="d-flex align-items-center">
                {!isSessionOpen ? (
                    <Settings to="/menu-congregaciones">
                        <i className="fas fa-cogs" />
                    </Settings>
                ) : (
                    <Congregation>
                        <div>{currentCongregation?.nombre}</div>
                        <div>{currentCongregation?.pais}</div>
                    </Congregation>
                )}
                <span className="mx-2">{t('footer.rights', 'Todos los derechos reservados, © {{year}}.', { year: new Date().getFullYear() })}</span>
                <span className="small">{version}</span>
            </div>
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.section`
    align-items: center;
    background-color: ${(props) => props.theme.footerBackground};
    color: white;
    display: flex;
    font-size: 0.75rem;
    height: 50px;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;

    @media (max-width: 576px) {
        flex-direction: column;
        height: 60px;
        padding: 10px;
        align-items: left;
    }

    .left {
        align-items: center;
        display: flex;
        flex-direction: row;

        a {
            color: white;
        }

        select {
            margin-left: 20px;
        }
    }
`;

const Settings = styled(Link)`
    color: white;
    font-size: 30px;
    padding-right: 10px;

    @media (max-width: 576px) {
        position: absolute;
        bottom: 15px;
        left: 10px;
        font-size: 20px;
    }
`;

const Congregation = styled.div`
    text-align: right;
    padding-right: 20px;
    border-right: 1px solid gray;
    margin-right: 10px;
    font-size: 10px;

    button{
        border: none;
        background-color: transparent;
        color: white;
    }
`;

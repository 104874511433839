import IProgram from 'types/IProgram';
import iconTreasures from 'assets/iconTreasures.png';
import SectionTitle from '../layout/SectionTitle';
import Input from 'components/general/Input';
import { useTranslation } from 'react-i18next';

interface Props {
    data: IProgram,
}

export default function FormTreasures ({ data }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <Input label={t('program.weekly-reading', 'Lectura semanal')} type="textarea" name="lectura_semanal" defaultValue={data.lectura_semanal} />
            <Input label={t('program.initial-song', 'Canción inicial')} type="number" name="cancion1" defaultValue={data.cancion1?.toString()} />

            <section className="mt-5">
                <SectionTitle text={t('title.treasures', 'Tesoros de la biblia')} className="vmc-treasures" icon={iconTreasures} />
                <Input label={`${t('program.speach', 'Dicurso')} (10 mins.)`} type="textarea" name="discurso" defaultValue={data.discurso} />
                <Input label={`${t('program.spiritual-gems', 'Busquemos perlas escondidas')} (10 mins.)`} type="textarea" name="busquemos_perlas" defaultValue={data.busquemos_perlas} />
                <Input label={`${t('program.bible-reading', 'Lectura de la Biblia')} (4 mins.)`} type="textarea" name="lectura" defaultValue={data.lectura} />
            </section>
        </>
    );
}

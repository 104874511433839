import * as React from 'react';
import SidebarWrapper from 'react-sidebar';
import styled from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size';
import Footer from 'components/layout/Footer';
import Topbar from 'components/layout/Topbar';
import Sidebar from './Sidebar';

interface Props {
    children: React.ReactNode,
}

const MainLayout = ({ children }: Props): JSX.Element => {
    const width = useWindowWidth();
    const [open, setOpen] = React.useState(false);
    const isDesktop = width > 800;
    const [docked, setDocked] = React.useState(isDesktop);

    const toggleOpen = () => {
        if (isDesktop) {
            setDocked(!docked);
        } else {
            setDocked(false);
            setOpen(!open);
        }
    };

    React.useMemo(() => {
        setDocked(isDesktop);
        setOpen(false);
    }, [isDesktop]);

    return (
        <SidebarWrapper sidebar={<Sidebar />} docked={docked} open={open} onSetOpen={setOpen} sidebarClassName="sidebar" overlayClassName="overlay">
            <Topbar onMenuClick={toggleOpen} />
            <Content>
                {children}
            </Content>
            <Footer />
        </SidebarWrapper>
    );
};

export default MainLayout;

const Content = styled.div`
    background-color:  ${(props) => props.theme.mainBackground};
    padding: 16px;
    min-height: calc(100vh - 100px);
`;

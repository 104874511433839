import useCongregations from 'hooks/useCongregations';
import ICongregation from 'types/ICongregation';
import Loader from 'react-spinners/PulseLoader';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsLanguage from './SettingsLanguage';

export default function SelectCongregation (): JSX.Element {
    const { data: congregationsData, isLoading } = useCongregations();
    const data = congregationsData as ICongregation[];
    const { t } = useTranslation();

    if (isLoading){
        return <div className="text-center"><Loader color="#4a6da7" size={10}/></div>;
    }

    return (
        <div className="mt-4">
            <div className="mb-3"><b>{t('program.select-congregation', 'Por favor seleccione su congregación')}:</b></div>
            {data.map((item: ICongregation) => (
                <Button key={item.id} to={`/?cid=${item.id}`}>
                    {item.nombre}
                    <div className="country">{item.pais}</div>
                </Button>
            ))}
            <SettingsLanguage />
        </div>
    );
}

const Button = styled(Link)`
    position: relative;
    border: 0.5px solid lightgray;
    padding: 20px;
    display: block;
    margin-bottom: 10px;

    .country{
        position: absolute;
        color: #727272;
        top: 3px;
        right: 6px;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase
    }
`;

import * as React from 'react';
import MainLoader from 'components/general/MainLoader';
import usePrograma from 'hooks/useProgram';
import IProgram from 'types/IProgram';
import CalendarBar from 'components/general/CalendarBar';
import FormTreasures from 'components/forms/FormTreasures';
import FormMinistry from 'components/forms/FormMinistry';
import FormChristianLiving from 'components/forms/FormChristianLiving';
import 'react-datepicker/dist/react-datepicker.css';
import Box from 'components/layout/Box';
import { Button, Alert } from 'reactstrap';
import { useMutation } from 'react-query';
import updatePrograma from 'hooks/updatePrograma';
import createPrograma from 'hooks/createPrograma';
import MainContext from 'components/layout/MainContext';
import { getMonday } from 'services/utils';
import { useTranslation } from 'react-i18next';

const initialData = {
    id: -1,
    fecha: '',
    cancion1: '',
    lectura_semanal: '',
    discurso: '',
    busquemos_perlas: '',
    lectura: '',
    smm1: '',
    smm1_fuente: '',
    smm1_duracion: '',
    smm2: '',
    smm2_fuente: '',
    smm2_duracion: '',
    smm3: '',
    smm3_fuente: '',
    smm3_duracion: '',
    smm4: '',
    smm4_fuente: '',
    smm4_duracion: '',
    cancion2: '',
    nvc1: '',
    nvc1_fuente: '',
    nvc1_duracion: '',
    nvc2: '',
    nvc2_fuente: '',
    nvc2_duracion: '',
    nvc3: '',
    nvc3_fuente: '',
    nvc3_duracion: '',
    estudio_biblico: '',
    cancion3: '',
    language: '',
};

export default function Program(): JSX.Element {
    const { selectedDate, setSelectedDate } = React.useContext(MainContext);
    const monday = getMonday(selectedDate);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const { data: programData, isLoading } = usePrograma(monday, currentLanguage);
    const data = programData as IProgram || initialData;

    const mutationUpdate = useMutation(updatePrograma, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            setIsSubmitting(false);
            setSubmitted(true);
        },
    });

    const mutationCreate = useMutation(createPrograma, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            setIsSubmitting(false);
            setSubmitted(true);
        },
    });

    const handleSubmit = async (event: React.FormEvent) => {
        setIsSubmitting(true);
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);

        const lectura_semanal = formData.get('lectura_semanal') as string;

        if (lectura_semanal.trim()){
            setIsError(false);

            const newData: IProgram = {
                id: data.id,
                fecha: monday,
                cancion1: parseInt(formData.get('cancion1') as string),
                lectura_semanal: formData.get('lectura_semanal') as string,
                discurso: formData.get('discurso') as string,
                busquemos_perlas: formData.get('busquemos_perlas') as string,
                lectura: formData.get('lectura') as string,
                smm1: formData.get('smm1') as string,
                smm1_fuente: formData.get('smm1_fuente') as string,
                smm1_duracion: parseInt(formData.get('smm1_duracion') as string),
                smm2: formData.get('smm2') as string,
                smm2_fuente: formData.get('smm2_fuente') as string,
                smm2_duracion: parseInt(formData.get('smm2_duracion') as string),
                smm3: formData.get('smm3') as string,
                smm3_fuente: formData.get('smm3_fuente') as string,
                smm3_duracion: parseInt(formData.get('smm3_duracion') as string),
                smm4: formData.get('smm4') as string,
                smm4_fuente: formData.get('smm4_fuente') as string,
                smm4_duracion: parseInt(formData.get('smm4_duracion') as string),
                cancion2: parseInt(formData.get('cancion2') as string),
                nvc1: formData.get('nvc1') as string,
                nvc1_fuente: formData.get('nvc1_fuente') as string,
                nvc1_duracion: parseInt(formData.get('nvc1_duracion') as string),
                nvc2: formData.get('nvc2') as string,
                nvc2_fuente: formData.get('nvc2_fuente') as string,
                nvc2_duracion: parseInt(formData.get('nvc2_duracion') as string),
                nvc3: formData.get('nvc3') as string,
                nvc3_fuente: formData.get('nvc3_fuente') as string,
                nvc3_duracion: parseInt(formData.get('nvc3_duracion') as string),
                estudio_biblico: formData.get('estudio_biblico') as string,
                cancion3: parseInt(formData.get('cancion3') as string),
                language: currentLanguage,
            };

            if (data.id === -1) {
                mutationCreate.mutate(newData);
            }else{
                mutationUpdate.mutate(newData);
            }
        }else{
            setIsError(true);
        }

        setIsSubmitting(false);
    };

    React.useEffect(() => {
        setSubmitted(false);
    }, [selectedDate]);

    return (
        <Box>
            <div className="p-5">
                {isLoading ? (
                    <MainLoader />
                ) : (
                    <>
                        <CalendarBar selectedDate={selectedDate} onChangeDate={setSelectedDate}/>
                        <form onSubmit={handleSubmit}>
                            <FormTreasures data={data}  />
                            <FormMinistry data={data}  />
                            <FormChristianLiving data={data} />
                            <div className="text-right">
                                <Button color="primary mt-5 px-5" disabled={isLoading || isSubmitting}>
                                    {data.id === -1 ? t('general.create', 'Crear') : t('general.save', 'Guardar')}
                                </Button>
                            </div>
                            {isError && <Alert color="danger" className="mt-4 mb-0">{t('error.add-minimum', 'Debe agregar al menos la lectura semanal')}</Alert>}
                            {submitted && <Alert color="success" className="mt-4 mb-0">{t('program.saved', 'Programa guardado correctamente')}</Alert>}
                        </form>
                    </>
                )}
            </div>
        </Box>
    );}

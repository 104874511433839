import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Roles } from 'services/constants';
import useIsRole from 'hooks/useIsRole';
import { useTranslation } from 'react-i18next';

const Sidebar = (): JSX.Element => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const isCoordinador = useIsRole(Roles.COORDINADOR);
    const isSupVmc = useIsRole(Roles.SUP_VMC);
    const isAnciano = useIsRole(Roles.ANCIANO);
    const isTranscriptor = useIsRole(Roles.TRANSCRIPTOR);

    const items = [
        {
            path: '/dashboard',
            title: t('menu.home', 'Inicio'),
            icon: 'fas fa-home',
            show: true,
        },
        {
            path: '/programa',
            title: t('menu.program', 'Programa'),
            icon: 'fas fa-list-ul',
            show: isAdmin || isTranscriptor,
        },
        {
            path: '/estudiantes',
            title: t('menu.students', 'Estudiantes'),
            icon: 'fas fa-users',
            show: isAdmin || isCoordinador || isSupVmc || isAnciano,
        },
        {
            path: '/congregaciones',
            title: t('menu.congregations', 'Congregaciones'),
            icon: 'fas fa-warehouse',
            show: isAdmin,
        },
        {
            path: '/usuarios',
            title: t('menu.users', 'Usuarios'),
            icon: 'fas fa-users-cog',
            show: isAdmin || isCoordinador,
        },
    ];

    return (
        <SidebarStyle>
            <SidebarTop>
                <LinkLogo to="/">
                    <b>VMC</b>
                    {' '}
                    | Admin
                </LinkLogo>
            </SidebarTop>
            <SidebarBody>
                {items.map((item) => (
                    <div key={item.path}>
                        {item.show &&  (
                            <MenuLink to={item.path} className={pathname === item.path ? 'active' : ''}>
                                <i className={item.icon} />
                                {item.title}
                            </MenuLink>
                        )}
                    </div>
                ))}
            </SidebarBody>
        </SidebarStyle>
    );
};

export default Sidebar;

const SidebarStyle = styled.div`
    position: relative;
    background-color: white;
    width: 200px;
`;

const SidebarTop = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.primary};
    color: white;
    display: flex;
    font-size: 20px;
    height: 50px;
    justify-content: center;
`;


const MenuLink = styled(Link)`
    background-color: white;
    border-bottom: solid 1px #ccc;
    color: #555;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    padding: 10px 0;
    padding-left: 10px;

    @media (max-width: 768px) {
        padding: 15px 0;
        padding-left: 10px;
    }

    i{
        padding-right: 10px;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    &:hover{
        text-decoration: none;
        color: ${(props) => props.theme.primary};
        background-color: #f5f5f5;
    }
`;

const SidebarBody = styled.div`
    width: 100%;
    height: calc(100vh - 50px);
    background-color: white;

    .active{
        background-color: #eceae7;
        border-right: 4px solid #2b436b;
        color: #2b436b;
        font-weight: bold;
    }
`;

const LinkLogo = styled(Link)`
    color: white;

    &:hover{
        text-decoration: none;
        color: white;
    }
`;

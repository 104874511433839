import { useTranslation } from 'react-i18next';
import { saveLanguage } from 'services/session';
import styled from 'styled-components';

export default function SettingsLanguage(): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const languages = [
        {
            value: 'es-US',
            label: t('general.es-US', 'Español'),
        },
        {   value: 'en-US',
            label: t('general.en-US', 'English'),
        },
    ];

    const changeLanguage = (newLanguage: string): void => {
        i18n.changeLanguage(newLanguage);
        saveLanguage(newLanguage);
    };

    return (
        <>
            <div className="mb-3 mt-5"><b>{t('program.select-language', 'Por favor seleccione el idioma preferido')}:</b></div>
            {languages.map((item) => (
                <div key={item.value}>
                    <LanguageButton onClick={() => changeLanguage(item.value)} type="button">{item.label}</LanguageButton>
                    {currentLanguage === item.value && <i className="fas fa-check"/>}
                </div>
            ))}

        </>
    );
}

const LanguageButton = styled.button`
    background: none;
    border: none;
    height: 40px;
`;

import useAssignmentsEstudiante from 'hooks/useAssignmentsEstudiante';
import IAssignment from 'types/IAssignment';
import Loader from 'react-spinners/PulseLoader';
import styled from 'styled-components';
import { getDateRangeFormatted, getSectionIcon, groupBy, getPartText } from 'services/utils';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number,
}

export default function HistorialEstudiante ({ id }: Props): JSX.Element {
    const {data: historialData, isLoading} = useAssignmentsEstudiante(id);
    const data = historialData as IAssignment[];
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    if (isLoading){
        return (
            <Container className="d-flex align-items-center justify-content-center">
                <Loader color="#4a6da7" size={10}/>
            </Container>
        );
    }

    const dataWithYear = data.map((item) => ({...item, year: (new Date(item.fecha)).getFullYear().toString()}));

    const groupByYear = groupBy('year');
    const grouped = groupByYear(dataWithYear);

    const array = Object.entries(grouped).sort((a, b) => parseInt(b[0]) - parseInt(a[0]));

    const getYearlyHistory = (items: IAssignment[]) => {
        return (
            <>
                {items.map((item: IAssignment) => (
                    <div key={item.id}>
                        <div className="d-flex">
                            <img src={getSectionIcon(item.tipo)} alt="" width="50px" height="50px" />
                            <div className="pl-2">
                                <small>{getDateRangeFormatted(new Date(item.fecha), currentLanguage)}</small>
                                <div>
                                    <small dangerouslySetInnerHTML={{ __html: getPartText(t, item.tipo, item.programa)}} />
                                </div>
                            </div>
                        </div>
                        <Vertical/>
                    </div>
                ))}
            </>
        );
    };

    return (
        <Container className="p-4">
            <div className="text-right mb-4"><small>{t('general.total', 'Total')}:<b> {data.length} {t('general.assignments', 'asignaciones')}</b></small></div>
            {array.map((itemGroup) => (
                <div key={itemGroup.toString()}>
                    <Year>
                        <div className="d-flex align-items-center">
                            <i className="fas fa-dot-circle vmc-primary" />
                            <div className="year" key={itemGroup[0]}>{itemGroup[0]}</div>
                        </div>
                        <small className="float-right cant">
                            {(itemGroup[1] as IAssignment[]).length} {(itemGroup[1] as IAssignment[]).length === 1 ? t('general.assignment', 'asignación') : t('general.assignments', 'asignaciones')}
                        </small>
                    </Year>
                    <Vertical />
                    {getYearlyHistory(itemGroup[1] as IAssignment[])}
                </div>
            ))}
            <Year>
                <i className="fas fa-dot-circle vmc-primary" />
            </Year>
        </Container>
    );
}

const Container = styled.div`
    height: 400px;
    overflow-y: auto;
    position: relative;
`;

const Vertical = styled.div`
    width: 28px;
    border-right: dotted #4a6da7 4px;
    height: 60px;
    display: flex;

`;

const Year = styled.div`
    display: flex;
    color: #4a6da7;
    font-weight: bold;
    align-items: center;
    margin-left: 18px;
    position: relative;
    justify-content: space-between;

    .year{
        font-size: 30px;
        padding-left: 35px;
        position: absolute;
    }

    .cant{
        color: gray;
    }
`;

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IProgram from 'types/IProgram';

export default function usePrograma (fecha: string, language: string): UseQueryResult {
    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                programa(fecha: "${fecha}", language: "${language || 'es-US'}") {
                    id
                    fecha
                    cancion1
                    lectura_semanal
                    discurso
                    busquemos_perlas
                    lectura
                    smm1
                    smm1_fuente
                    smm1_duracion
                    smm2
                    smm2_fuente
                    smm2_duracion
                    smm3
                    smm3_fuente
                    smm3_duracion
                    smm4
                    smm4_fuente
                    smm4_duracion
                    cancion2
                    nvc1
                    nvc1_fuente
                    nvc1_duracion
                    nvc2
                    nvc2_fuente
                    nvc2_duracion
                    nvc3
                    nvc3_fuente
                    nvc3_duracion
                    estudio_biblico
                    cancion3,
                    language,
                }
            }
        `);

        return response.programa;
    };

    return useQuery<IProgram, Error>(['programa', fecha], loadData);
}

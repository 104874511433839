import * as React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { clearHtml, findAssignedName, getDateRangeFormatted, getSalaName, isSalaActive } from 'services/utils';
import ISala from 'types/ISala';
import IAssignment from 'types/IAssignment';
import { Kind } from 'services/constants';
import IReportData from 'types/IReportData';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 35,
        paddingHorizontal: 35,
        fontFamily: 'Helvetica',
    },
    title: {
        fontSize: 30,
        fontWeight: 'heavy',
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
    },
    subtitle: {
        fontSize: 12,
        marginTop: '3mm',
        marginBottom: '1mm',
    },
    header: {
        fontSize: 10,
        textAlign: 'right',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        left: 0,
        right: 30,
        textAlign: 'right',
        color: 'grey',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 10,
        marginBottom: '1mm',
    },
    note: {
        position: 'absolute',
        bottom: '3cm',
        paddingRight: 70,
        left: 35,
    },
    footer: {
        position: 'absolute',
        bottom: 35,
        left: 35,
    },
});
interface Props {
    id: number
    data: IReportData[]
    salas: ISala[]
}

export default function PdfS89Ssingle({ data, salas, id }: Props): JSX.Element{
    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const getSingle = (text: string, kind: number, duration: number | null, assignments: IAssignment[], date: string, sala: number) => {
        if (!text) return <></>;

        return (
            <View style={styles.body}>
                <Text style={styles.title}>ASIGNACIÓN PARA LA REUNIÓN</Text>
                <Text style={styles.title}>VIDA Y MINISTERIO CRISTIANOS</Text>

                <Text style={{fontSize: 30, marginTop: '10mm'}}>
                    <Text style={{fontFamily: 'Helvetica-Bold'}}>Nombre: </Text>
                    <Text style={{fontSize: 25}}>{findAssignedName(kind, assignments)}</Text>
                </Text>

                <Text style={{fontSize: 30, marginTop: '5mm', fontFamily: 'Helvetica-Bold'}}>Ayudante:</Text>

                <Text style={{fontSize: 30, marginTop: '5mm'}}>
                    <Text style={{fontFamily: 'Helvetica-Bold'}}>Fecha: </Text>
                    <Text style={{fontSize: 25}}>{date}</Text>
                </Text>

                <Text style={{fontFamily: 'Helvetica-Bold', marginTop: '1.8cm', marginBottom: '8mm'}}>Tipo de intervención:</Text>
                <Text style={{marginLeft: '1cm'}}>{text}</Text>
                <Text style={{marginLeft: '1cm'}}>{`${duration ? `(${duration} mins.)` : ''}`}</Text>

                <Text style={{fontFamily: 'Helvetica-Bold', marginTop: '1.8cm', marginBottom: '8mm'}}>Se presentará en:</Text>
                <Text style={{marginLeft: '1cm'}}>{getSalaName(sala, salas)}</Text>
            </View>
        );
    };

    const getDouble = (text: string, kind1: number, kind2: number, duration: number, assignments: IAssignment[], date: string, sala: number) => {
        if (!text) return <></>;

        const student1 = findAssignedName(kind1, assignments);
        const student2 = findAssignedName(kind2, assignments);

        return (
            <View style={styles.body}>
                <Text style={styles.title}>ASIGNACIÓN PARA LA REUNIÓN</Text>
                <Text style={styles.title}>VIDA Y MINISTERIO CRISTIANOS</Text>

                <Text style={{fontSize: 30, marginTop: '10mm'}}>
                    <Text style={{fontFamily: 'Helvetica-Bold'}}>Nombre: </Text>
                    <Text style={{fontSize: 25}}>{student1}</Text>
                </Text>

                <Text style={{fontSize: 30, marginTop: '5mm'}}>
                    <Text style={{fontFamily: 'Helvetica-Bold'}}>Ayudante: </Text>
                    <Text style={{fontSize: 25}}>{student2}</Text>
                </Text>

                <Text style={{fontSize: 30, marginTop: '5mm'}}>
                    <Text style={{fontFamily: 'Helvetica-Bold'}}>Fecha: </Text>
                    <Text style={{fontSize: 25}}>{date}</Text>
                </Text>

                <Text style={{fontFamily: 'Helvetica-Bold', marginTop: '1.8cm', marginBottom: '8mm'}}>Tipo de intervención:</Text>
                <Text style={{marginLeft: '1cm'}}>{text}</Text>
                <Text style={{marginLeft: '1cm'}}>{`${duration ? `(${duration} mins.)` : ''}`}</Text>

                <Text style={{fontFamily: 'Helvetica-Bold', marginTop: '1.8cm', marginBottom: '8mm'}}>Se presentará en:</Text>
                <Text style={{marginLeft: '1cm'}}>{getSalaName(sala, salas)}</Text>
            </View>
        );
    };

    return (
        <Document>
            {data.map((item) => {
                if (!item.program) return <></>;

                const date = getDateRangeFormatted(new Date(item.date), currentLanguage);
                const lectura = clearHtml(item.program.lectura);
                const smm1 = clearHtml(item.program.smm1);
                const smm2 = clearHtml(item.program.smm2);
                const smm3 = clearHtml(item.program.smm3);
                const smm4 = clearHtml(item.program.smm4);

                if (!item.assignments.some((item) => item.id === id)) return <></>;
                const filteredAssignments = item.assignments.filter((item) => item.id === id);

                const mainKind = filteredAssignments[0].tipo;

                return (
                    <Page key={`${item.program.id}-${mainKind}-${date}`}>
                        {mainKind === Kind.LECTURA_SALA_1 && getSingle(`Lectura: ${lectura}`, Kind.LECTURA_SALA_1, 4, item.assignments, date, 1)}
                        {mainKind === Kind.PARTE_1_SALA_1 && getDouble(smm1, Kind.PARTE_1_SALA_1, Kind.PARTE_1_AYUDANTE_SALA_1, item.program.smm1_duracion, item.assignments, date, 1)}
                        {mainKind === Kind.PARTE_2_SALA_1 && getDouble(smm2, Kind.PARTE_2_SALA_1, Kind.PARTE_2_AYUDANTE_SALA_1, item.program.smm2_duracion, item.assignments, date, 1)}
                        {mainKind === Kind.PARTE_3_SALA_1 && getDouble(smm3, Kind.PARTE_3_SALA_1, Kind.PARTE_3_AYUDANTE_SALA_1, item.program.smm3_duracion, item.assignments, date, 1)}
                        {mainKind === Kind.PARTE_4_SALA_1 && getDouble(smm4, Kind.PARTE_4_SALA_1, Kind.PARTE_4_AYUDANTE_SALA_1, item.program.smm4_duracion, item.assignments, date, 1)}


                        {existSala2 && (
                            <>
                                {mainKind === Kind.LECTURA_SALA_2 && getSingle(`Lectura: ${lectura}`, Kind.LECTURA_SALA_2, 4, item.assignments, date, 2)}
                                {mainKind === Kind.PARTE_1_SALA_2 && getDouble(smm1, Kind.PARTE_1_SALA_2, Kind.PARTE_1_AYUDANTE_SALA_2, item.program.smm1_duracion, item.assignments, date, 2)}
                                {mainKind === Kind.PARTE_2_SALA_2 && getDouble(smm2, Kind.PARTE_2_SALA_2, Kind.PARTE_2_AYUDANTE_SALA_2, item.program.smm2_duracion, item.assignments, date, 2)}
                                {mainKind === Kind.PARTE_3_SALA_2 && getDouble(smm3, Kind.PARTE_3_SALA_2, Kind.PARTE_3_AYUDANTE_SALA_2, item.program.smm3_duracion, item.assignments, date, 2)}
                                {mainKind === Kind.PARTE_4_SALA_2 && getDouble(smm4, Kind.PARTE_4_SALA_2, Kind.PARTE_4_AYUDANTE_SALA_2, item.program.smm4_duracion, item.assignments, date, 2)}
                            </>
                        )}

                        {existSala3 && (
                            <>
                                {mainKind === Kind.LECTURA_SALA_3 && getSingle(`Lectura: ${lectura}`, Kind.LECTURA_SALA_3, 4, item.assignments, date, 3)}
                                {mainKind === Kind.PARTE_1_SALA_3 && getDouble(smm1, Kind.PARTE_1_SALA_3, Kind.PARTE_1_AYUDANTE_SALA_3, item.program.smm1_duracion, item.assignments, date, 3)}
                                {mainKind === Kind.PARTE_2_SALA_3 && getDouble(smm2, Kind.PARTE_2_SALA_3, Kind.PARTE_2_AYUDANTE_SALA_3, item.program.smm2_duracion, item.assignments, date, 3)}
                                {mainKind === Kind.PARTE_3_SALA_3 && getDouble(smm3, Kind.PARTE_3_SALA_3, Kind.PARTE_3_AYUDANTE_SALA_3, item.program.smm3_duracion, item.assignments, date, 3)}
                                {mainKind === Kind.PARTE_4_SALA_3 && getDouble(smm4, Kind.PARTE_4_SALA_3, Kind.PARTE_4_AYUDANTE_SALA_3, item.program.smm4_duracion, item.assignments, date, 3)}
                            </>
                        )}
                        <Text style={styles.note} fixed>
                            <Text wrap={false} style={{fontFamily: 'Helvetica-Bold'}}>Nota al estudiante: </Text>En la <Text style={{fontFamily: 'Helvetica-Oblique'}}>Guía de actividades</Text> encontrará la información que necesita para su intervención, así como el aspecto de la oratoria que debe preparar con la ayuda del folleto Maestros.
                        </Text>
                        <Text style={styles.footer} fixed>S-89-S       11/20</Text>
                    </Page>
                );
            })}
        </Document>
    );
}

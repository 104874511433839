import IConfiguracion from 'types/IConfiguracion';
import useConfiguracion from './useConfiguracion';

export default function useNumberGroups(): number {
    const { data } = useConfiguracion('GRUPOS');
    const grupos = data as IConfiguracion;

    if (grupos){
        return parseInt(grupos.value);
    }

    return 0;
}


import { gql } from 'graphql-request';
import API from 'services/api';
import IEvento from 'types/IEvento';

const createEvento = async (data: IEvento): Promise<IEvento> => {
    return await API.graphql(gql`
        mutation {
            createEvento(
                fecha: "${data.fecha}"
                tipo: ${data.tipo}
                congregacion_id: ${data.congregacion_id}
                observaciones: "${data.observaciones}"
            ){
                id
            }
        }
    `);
};

export default createEvento;

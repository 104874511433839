import IProgram from 'types/IProgram';
import iconMinistry from 'assets/iconMinistry.png';
import { Col, Row } from 'reactstrap';
import SectionTitle from '../layout/SectionTitle';
import ISala from 'types/ISala';
import useSalas from 'hooks/useSalas';
import { getSalaName, isSalaActive } from 'services/utils';
import { Kind } from 'services/constants';
import Assignment from 'components/general/Assignment';
import { useTranslation } from 'react-i18next';

interface Props {
    program: IProgram,
}

export default function SectionMinistry ({ program }: Props): JSX.Element {
    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];
    const { t } = useTranslation();

    if(!salas) return <></>;

    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const sala1Name = getSalaName(1, salas);
    const sala2Name = getSalaName(2, salas);
    const sala3Name = getSalaName(3, salas);
    const existExtraSalas = existSala2 || existSala3;

    return (
        <section>
            <SectionTitle text={t('title.apply-ministry', 'Seamos mejores maestros')} className="vmc-ministry" icon={iconMinistry} />
            <Row>
                <Col md="8" className="mb-2"><strong dangerouslySetInnerHTML={{ __html: program.smm1 }} /> ({program.smm1_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.smm1_fuente }} /></Col>
                <Col md="4">
                    {existExtraSalas && <div className="badge-vmc-small mb-2">{sala1Name}</div>}
                    <Assignment type={Kind.PARTE_1_SALA_1} />
                    <Assignment type={Kind.PARTE_1_AYUDANTE_SALA_1} isAux />
                    {existSala2 && (
                        <>
                            <div className="badge-vmc-small mt-4 mb-2">{sala2Name}</div>
                            <Assignment type={Kind.PARTE_1_SALA_2} />
                            <Assignment type={Kind.PARTE_1_AYUDANTE_SALA_2} isAux />
                        </>
                    )}
                    {existSala3 && (
                        <>
                            <div className="badge-vmc-small mt-4 mb-2">{sala3Name}</div>
                            <Assignment type={Kind.PARTE_1_SALA_3} />
                            <Assignment type={Kind.PARTE_1_AYUDANTE_SALA_3} isAux />
                        </>
                    )}
                </Col>
            </Row>
            {!!program.smm2?.length && (
                <Row>
                    <Col md="8" className="mb-2"><strong dangerouslySetInnerHTML={{ __html: program.smm2 }} /> ({program.smm2_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.smm2_fuente }} /></Col>
                    <Col md="4">
                        {existExtraSalas && <div className="badge-vmc-small mb-2">{sala1Name}</div>}
                        <Assignment type={Kind.PARTE_2_SALA_1} />
                        <Assignment type={Kind.PARTE_2_AYUDANTE_SALA_1} isAux />
                        {existSala2 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala2Name}</div>
                                <Assignment type={Kind.PARTE_2_SALA_2} />
                                <Assignment type={Kind.PARTE_2_AYUDANTE_SALA_2} isAux />
                            </>
                        )}
                        {existSala3 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala3Name}</div>
                                <Assignment type={Kind.PARTE_2_SALA_3} />
                                <Assignment type={Kind.PARTE_2_AYUDANTE_SALA_3} isAux />
                            </>
                        )}
                    </Col>
                </Row>
            )}
            {!!program.smm3?.length && (
                <Row>
                    <Col md="8" className="mb-2"><strong dangerouslySetInnerHTML={{ __html: program.smm3 }} /> ({program.smm3_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.smm3_fuente }} /></Col>
                    <Col md="4">
                        {existExtraSalas && <div className="badge-vmc-small mb-2">{sala1Name}</div>}
                        <Assignment type={Kind.PARTE_3_SALA_1} />
                        <Assignment type={Kind.PARTE_3_AYUDANTE_SALA_1} isAux />
                        {existSala2 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala2Name}</div>
                                <Assignment type={Kind.PARTE_3_SALA_2} />
                                <Assignment type={Kind.PARTE_3_AYUDANTE_SALA_2} isAux />
                            </>
                        )}
                        {existSala3 && (
                            <>
                                <div className="badge-vmc-small mt-4 mb-2">{sala3Name}</div>
                                <Assignment type={Kind.PARTE_3_SALA_3} />
                                <Assignment type={Kind.PARTE_3_AYUDANTE_SALA_3} isAux />
                            </>
                        )}
                    </Col>
                </Row>
            )}
            {!!program.smm4?.length && (
                <Row>
                    <Col md="8" className="mb-2"><strong dangerouslySetInnerHTML={{ __html: program.smm4 }} /> ({program.smm4_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.smm4_fuente }} /></Col>
                    <Col md="4">
                        {existExtraSalas && <div className="badge-vmc-small">{sala1Name}</div>}
                        <Assignment type={Kind.PARTE_4_SALA_1} />
                        <Assignment type={Kind.PARTE_4_AYUDANTE_SALA_1} isAux />
                        {existSala2 && (
                            <>
                                <div className="badge-vmc-small mt-4">{sala2Name}</div>
                                <Assignment type={Kind.PARTE_4_SALA_2} />
                                <Assignment type={Kind.PARTE_4_AYUDANTE_SALA_2} isAux />
                            </>
                        )}
                        {existSala3 && (
                            <>
                                <div className="badge-vmc-small mt-4">{sala3Name}</div>
                                <Assignment type={Kind.PARTE_4_SALA_3} />
                                <Assignment type={Kind.PARTE_4_AYUDANTE_SALA_3} isAux />
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </section>
    );
}

import * as React from 'react';
import IconButton from 'components/general/IconButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import useEstudiante from 'hooks/useEstudiante';
import IEstudiante from 'types/IEstudiante';
import deleteEstudiante from 'hooks/deleteEstudiante';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number,
}

export default function DeleteEstudianteButton ({id}: Props): JSX.Element {
    const {data: estudianteData} = useEstudiante(id);
    const data = estudianteData as IEstudiante;
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const [show, setShow] = React.useState(false);

    const toggle = () => setShow(!show);

    const mutationDelete = useMutation(deleteEstudiante, {
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allEstudiantes');
            toggle();
        },
    });

    const handleDelete = () => {
        mutationDelete.mutate(id);
    };

    if (!data) return <></>;

    return (
        <>
            <IconButton onClick={toggle} icon="fas fa-trash-alt" />

            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText="Sí, confirmo"
                cancelBtnText="No, volver"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={`${data.nombres} ${data.apellidos}`}
                onConfirm={handleDelete}
                onCancel={toggle}
            >
                {t('general.sure-delete', '¿Está seguro de eliminar?')}
            </SweetAlert>
        </>
    );
}


import { gql } from 'graphql-request';
import API from 'services/api';
import IAssignment from 'types/IAssignment';

const createAsignacion = async (data: IAssignment): Promise<IAssignment> => {
    return await API.graphql(gql`
        mutation {
            createAsignacion(
                fecha: "${data.fecha}"
                tipo: ${data.tipo}
                estudiante_id: ${data.estudiante_id}
                congregacion_id: ${data.congregacion_id}
            ){
                id
            }
        }
    `);
};

export default createAsignacion;

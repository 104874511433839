import IProgram from 'types/IProgram';
import iconMinistry from 'assets/iconMinistry.png';
import { Col, Row } from 'reactstrap';
import SectionTitle from '../layout/SectionTitle';
import Input from 'components/general/Input';
import {useTranslation} from 'react-i18next';

interface Props {
    data: IProgram,
}

export default function FormMinistry ({ data }: Props): JSX.Element {
    const { t } = useTranslation();

    return (
        <section className="mt-5">
            <SectionTitle text={t('title.apply-ministry', 'Seamos mejores maestros')} className="vmc-ministry" icon={iconMinistry} />

            <Row>
                <Col xl="5"><Input label={t('program.part', 'Parte {{n}}', { n: 1 })} name="smm1" type="textarea" defaultValue={data.smm1} /></Col>
                <Col xl="2"><Input label={t('program.duration', 'Duración')} name="smm1_duracion" type="number" defaultValue={data.smm1_duracion?.toString()} /></Col>
                <Col xl="5"><Input label={t('program.source', 'Fuente')} name="smm1_fuente" type="textarea" defaultValue={data.smm1_fuente} /></Col>
            </Row>

            <Row>
                <Col xl="5"><Input label={t('program.part', 'Parte {{n}}', { n: 2 })} name="smm2" type="textarea" defaultValue={data.smm2} /></Col>
                <Col xl="2"><Input label={t('program.duration', 'Duración')} name="smm2_duracion" type="number" defaultValue={data.smm2_duracion?.toString()} /></Col>
                <Col xl="5"><Input label={t('program.source', 'Fuente')} name="smm2_fuente" type="textarea" defaultValue={data.smm2_fuente} /></Col>
            </Row>

            <Row>
                <Col xl="5"><Input label={t('program.part', 'Parte {{n}}', { n: 3 })} name="smm3" type="textarea" defaultValue={data.smm3} /></Col>
                <Col xl="2"><Input label={t('program.duration', 'Duración')} name="smm3_duracion" type="number" defaultValue={data.smm3_duracion?.toString()} /></Col>
                <Col xl="5"><Input label={t('program.source', 'Fuente')} name="smm3_fuente" type="textarea" defaultValue={data.smm3_fuente} /></Col>
            </Row>

            <Row>
                <Col xl="5"><Input label={t('program.part', 'Parte {{n}}', { n: 4 })} name="smm4" type="textarea" defaultValue={data.smm4} /></Col>
                <Col xl="2"><Input label={t('program.duration', 'Duración')} name="smm4_duracion" type="number" defaultValue={data.smm4_duracion?.toString()} /></Col>
                <Col xl="5"><Input label={t('program.source', 'Fuente')} name="smm4_fuente" type="textarea" defaultValue={data.smm4_fuente} /></Col>
            </Row>
        </section>
    );
}

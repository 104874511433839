
import { gql } from 'graphql-request';
import API from 'services/api';
import IProgram from 'types/IProgram';
import { escapeHtml } from 'services/utils';

const createPrograma = async (data: IProgram): Promise<IProgram> => {
    return await API.graphql(gql`
        mutation {
            createPrograma(
                fecha: "${data.fecha}",
                cancion1: ${isNaN(data.cancion1) ? null : data.cancion1},
                lectura_semanal: "${escapeHtml(data.lectura_semanal)}",
                discurso: "${escapeHtml(data.discurso)}",
                busquemos_perlas: "${escapeHtml(data.busquemos_perlas)}",
                lectura: "${escapeHtml(data.lectura)}",
                smm1: "${escapeHtml(data.smm1)}",
                smm1_fuente: "${escapeHtml(data.smm1_fuente)}",
                smm1_duracion: ${isNaN(data.smm1_duracion) ? null : data.smm1_duracion},
                smm2: "${escapeHtml(data.smm2)}",
                smm2_fuente: "${escapeHtml(data.smm2_fuente)}",
                smm2_duracion: ${isNaN(data.smm2_duracion) ? null : data.smm2_duracion},
                smm3: "${escapeHtml(data.smm3)}",
                smm3_fuente: "${escapeHtml(data.smm3_fuente)}",
                smm3_duracion: ${isNaN(data.smm3_duracion) ? null : data.smm3_duracion},
                smm4: "${escapeHtml(data.smm4)}",
                smm4_fuente: "${escapeHtml(data.smm4_fuente)}",
                smm4_duracion: ${isNaN(data.smm4_duracion) ? null : data.smm4_duracion},
                cancion2: ${isNaN(data.cancion2) ? null : data.cancion2},
                nvc1: "${escapeHtml(data.nvc1)}",
                nvc1_fuente: "${escapeHtml(data.nvc1_fuente)}",
                nvc1_duracion: ${isNaN(data.nvc1_duracion) ? null : data.nvc1_duracion},
                nvc2: "${escapeHtml(data.nvc2)}",
                nvc2_fuente: "${escapeHtml(data.nvc2_fuente)}",
                nvc2_duracion: ${isNaN(data.nvc2_duracion) ? null : data.nvc2_duracion},
                nvc3: "${escapeHtml(data.nvc3)}",
                nvc3_fuente: "${escapeHtml(data.nvc3_fuente)}",
                nvc3_duracion: ${isNaN(data.nvc3_duracion) ? null : data.nvc3_duracion},
                estudio_biblico: "${escapeHtml(data.estudio_biblico)}",
                cancion3: ${isNaN(data.cancion3) ? null : data.cancion3},
                language: "${escapeHtml(data.language)}",
            ){
                id
            }
        }
    `);
};

export default createPrograma;

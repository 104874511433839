import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import useCongregationId from './useCongregationId';

export default function useEstudiantesByCapacity(capacity: number): UseQueryResult {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
        query {
            estudiantesByCapacity(congregacion_id: ${congregationId}, capacity: "${capacity}") {
                id
                nombres
                apellidos
                sexo
                mas_reciente{
                    fecha
                }
            }
        }
      `);
        return response.estudiantesByCapacity;
    };

    return useQuery(['estudiantes', capacity], loadData);
}

import { QueryClient, QueryClientProvider } from 'react-query';
import LoginForm from 'components/forms/LoginForm';
import Dashboard from 'components/pages/Dashboard';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'components/layout/PrivateRoute';
import Page404 from 'components/pages/Page404';
import Estudiantes from 'components/pages/Estudiantes';
import Users from 'components/pages/Users';
import Program from 'components/pages/Program';
import Salas from 'components/pages/Settings';
import Congregations from 'components/pages/Congregations';
import AuthLayout from 'components/layout/AuthLayout';
import MainLayout from 'components/layout/MainLayout';
import GeneralLayout from 'components/layout/GeneralLayout';
import SelectCongregation from 'components/forms/SelectCongregation';
import { MainContextProvider } from 'components/layout/MainContext';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles';
import theme from 'theme';
import MainPage from 'components/pages/MainPage';
import { Suspense } from 'react';
import Loader from 'react-spinners/PulseLoader';

const queryClient = new QueryClient();

export default function App(): JSX.Element {
    return (
        <Suspense fallback={<Loader color="#4a6da7" size={10}/>}>
            <QueryClientProvider client={queryClient}>
                <GlobalStyles />
                <ThemeProvider theme={theme}>
                    <MainContextProvider>
                        <Router>
                            <Switch>
                                <PrivateRoute exact path="/dashboard"><MainLayout><Dashboard /></MainLayout></PrivateRoute>
                                <PrivateRoute exact path="/estudiantes"><MainLayout><Estudiantes /></MainLayout></PrivateRoute>
                                <PrivateRoute exact path="/usuarios"><MainLayout><Users /></MainLayout></PrivateRoute>
                                <PrivateRoute exact path="/configuracion"><MainLayout><Salas /></MainLayout></PrivateRoute>
                                <PrivateRoute exact path="/congregaciones"><MainLayout><Congregations /></MainLayout></PrivateRoute>
                                <PrivateRoute exact path="/programa"><MainLayout><Program /></MainLayout></PrivateRoute>
                                <Route exact path="/"><GeneralLayout><MainPage /></GeneralLayout></Route>
                                <Route exact path="/login"><AuthLayout><LoginForm /></AuthLayout></Route>
                                <Route exact path="/menu-congregaciones"><AuthLayout><SelectCongregation /></AuthLayout></Route>
                                <Route><GeneralLayout><Page404 /></GeneralLayout></Route>
                            </Switch>
                        </Router>
                    </MainContextProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </Suspense>
    );
}

import { gql } from 'graphql-request';
import API from 'services/api';
import IUser from 'types/IUser';

const updateUser = async (data: IUser): Promise<IUser> => {
    return await API.graphql(gql`
        mutation {
            updateUser(
                id: ${data.id}
                name: "${data.name}"
                lastname: "${data.lastname}"
                email: "${data.email}"
                password: "${data.password}"
                congregacion_id: ${data.congregacion_id}
            ){
                id
            }
        }
    `);
};

export default updateUser;

import * as React from 'react';
import IconButton from 'components/general/IconButton';
import SweetAlert from 'react-bootstrap-sweetalert';
import useUser from 'hooks/useUser';
import IUser from 'types/IUser';
import deleteUser from 'hooks/deleteUser';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number,
}

export default function DeleteUserButton ({id}: Props): JSX.Element {
    const {data: userData} = useUser(id);
    const data = userData as IUser;
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const [show, setShow] = React.useState(false);

    const toggle = () => setShow(!show);

    const mutationDelete = useMutation(deleteUser, {
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allUsers');
            toggle();
        },
    });

    const handleDelete = () => {
        mutationDelete.mutate(id);
    };

    if (!data) return <></>;

    return (
        <>
            <IconButton onClick={toggle} icon="fas fa-trash-alt" />

            <SweetAlert
                show={show}
                warning
                showCancel
                confirmBtnText={t('general.yes-confirm', 'Sí, confirmo')}
                cancelBtnText={t('general.no-back', 'No, volver')}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={`${data.name} ${data.lastname}`}
                onConfirm={handleDelete}
                onCancel={toggle}
            >
                {t('general.sure-delete', '¿Está seguro de eliminar?')}
            </SweetAlert>
        </>
    );
}

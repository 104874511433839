import IProgram from 'types/IProgram';
import iconChristianLiving from 'assets/iconChristianLiving.png';
import { Col, Row } from 'reactstrap';
import SectionTitle from '../layout/SectionTitle';
import Input from 'components/general/Input';
import { useTranslation } from 'react-i18next';

interface Props {
    data: IProgram,
}

export default function FormChristianLiving ({ data }: Props): JSX.Element {
    const {t} = useTranslation();

    return (
        <section className="mt-5">
            <SectionTitle text={t('title.living-christians', 'Nuestra vida cristiana')} className="vmc-christian-living" icon={iconChristianLiving} />
            <Input label={t('program.song', 'Canción')} type="number" name="cancion2" defaultValue={data.cancion2?.toString()} />

            <Row>
                <Col lg="5"><Input label={t('program.part', 'Parte {{n}}', { n: 1 })} name="nvc1" type="textarea" defaultValue={data.nvc1} /></Col>
                <Col lg="2"><Input label={t('program.duration', 'Duración')} name="nvc1_duracion" type="number" defaultValue={data.nvc1_duracion?.toString()} /></Col>
                <Col lg="5"><Input label={t('program.source', 'Fuente')} name="nvc1_fuente" type="textarea" defaultValue={data.nvc1_fuente} /></Col>
            </Row>

            <Row>
                <Col lg="5"><Input label={t('program.part', 'Parte {{n}}', { n: 2 })} name="nvc2" type="textarea" defaultValue={data.nvc2} /></Col>
                <Col lg="2"><Input label={t('program.duration', 'Duración')} name="nvc2_duracion" type="number" defaultValue={data.nvc2_duracion?.toString()} /></Col>
                <Col lg="5"><Input label={t('program.source', 'Fuente')} name="nvc2_fuente" type="textarea" defaultValue={data.nvc2_fuente} /></Col>
            </Row>

            <Row>
                <Col lg="5"><Input label={t('program.part', 'Parte {{n}}', { n: 3 })} name="nvc3" type="textarea" defaultValue={data.nvc3} /></Col>
                <Col lg="2"><Input label={t('program.duration', 'Duración')} name="nvc3_duracion" type="number" defaultValue={data.nvc3_duracion?.toString()} /></Col>
                <Col lg="5"><Input label={t('program.source', 'Fuente')} name="nvc3_fuente" type="textarea" defaultValue={data.nvc3_fuente} /></Col>
            </Row>

            <Input label={`${t('program.bible-study', 'Estudio bíblico de la congregación')} (30 mins.)`}  type="textarea" name="estudio_biblico" defaultValue={data.estudio_biblico} />

            <Input label={t('program.ending-song', 'Canción final')} name="cancion3" defaultValue={data.cancion3?.toString()} />
        </section>
    );
}

import styled from 'styled-components';
import { Card } from 'reactstrap';

interface Props {
    title?: string,
    children: React.ReactNode
}

export default function Box ({ title, children }: Props): JSX.Element {
    return(
        <>
            {title && <TitleStyled>{title}</TitleStyled>}
            <CardStyled>
                {children}
            </CardStyled>
        </>
    );
}

const CardStyled = styled(Card)`
    padding: 20px;
    margin-bottom: 35px;
    min-height: calc(100vh - 203px);
`;

const TitleStyled = styled.h1`
    color: ${(props) => props.theme.primary};
    font-size: 1.5rem;
    padding-right: 20px;
    text-align: right;
    font-weight: 400;

    @media (max-width: 768px) {
        padding-right: 0px;
    }
`;

import Box from 'components/layout/Box';
import Meeting from 'components/general/Meeting';
import styled from 'styled-components';

export default function Dashboard(): JSX.Element {
    return (
        <Box>
            <Container>
                <Meeting />
            </Container>
        </Box>
    );
}

const Container = styled.div`
    padding: 50px;

    @media(max-width: 768px){
        padding: 0px;
    }
`;

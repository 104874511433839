import { useTranslation } from 'react-i18next';
import { Eventos } from 'services/constants';
import { getEventoText } from 'services/utils';
import styled from 'styled-components';

interface Props {
    eventType: number,
    observations?: string,
}

export default function MeetingCanceled ({ eventType, observations }: Props): JSX.Element {
    const { t } = useTranslation();
    
    return (
        <Container>
            {eventType === Eventos.OTRO ? (
                <>{`- ${observations} -`}</>
            ):(
                <>{`- ${getEventoText(eventType, t)} -`}</>
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: calc(100vh - 330px);
    font-style: italic;
    font-size: 24px;

    @media(max-width: 500px){
        height: calc(100vh - 260px);
        font-size: 18px;
    }
`;

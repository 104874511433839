/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ISelectOption from 'types/ISelectOption';

export const MAX_GROUPS = 10;

export const TiposOptions = (t: any): ISelectOption[] => [
    { value: 'Publicador bautizado', label: t('type.baptized-publisher', 'Publicador bautizado') },
    { value: 'Precursor', label: t('type.pioneer', 'Precursor')},
    { value: 'Publicador no bautizado', label: t('type.unbaptized-publisher', 'Publicador no bautizado')},
    { value: 'No publicador', label: t('type.non-publisher', 'No publicador')},
];

export const PrivilegiosOptions = (t: any): ISelectOption[] => [
    { value: 'Anciano', label: t('privilege.elder', 'Anciano')},
    { value: 'Siervo ministerial', label: t('privilege.ministerial-servant', 'Siervo ministerial')},
];

export const SexoOptions: ISelectOption[] = [
    { value: 'Masculino', label: '🟦' },
    { value: 'Femenino', label: '🟥' }
];

export const StatusOptions = (t: any): any[] => [
    { value: true, label: t('status.unavailable', 'No disponible') },
    { value: false, label: t('status.available', 'Disponible') }
];

export const RolesOptions = (t: any): any[] => [
    { value: 10, label: t('role.elder', 'Anciano' )},
    { value: 30, label: t('role.vmc-overseer', 'Sup. VMC' )},
    { value: 50, label: t('role.coordinator', 'Coordinador' )},
    { value: 55, label: t('role.transcriptor', 'Transcriptor' )},
    { value: 60, label: t('role.super-admin', 'SuperAdmin') }
];

export const Roles = {
    ANCIANO: 10,
    SUP_VMC: 30,
    COORDINADOR: 50,
    TRANSCRIPTOR: 55,
    SUPER_ADMIN: 60,
};

export const Eventos = {
    VISITA_CIRCUITO: 1,
    ASAMBLEA_CIRCUITO_SUP: 2,
    ASAMBLEA_CIRCUITO_SUC: 3,
    ASAMBLEA_REGIONAL: 4,
    OTRO: 5,
    NOTA: 6,
};

export const Kind = {
    PRESIDENCIA: 0,

    ORACION_INICIO: 31,

    DISCURSO: 1,
    PERLAS: 2,
    ENCARGADO_SALA_2: 32,
    ENCARGADO_SALA_3: 33,
    LECTURA_SALA_1: 3,
    LECTURA_SALA_2: 17,
    LECTURA_SALA_3: 24,

    PARTE_1_SALA_1: 34,
    PARTE_1_AYUDANTE_SALA_1: 35,
    PARTE_1_SALA_2: 36,
    PARTE_1_AYUDANTE_SALA_2: 37,
    PARTE_1_SALA_3: 38,
    PARTE_1_AYUDANTE_SALA_3: 39,

    PARTE_2_SALA_1: 40,
    PARTE_2_AYUDANTE_SALA_1: 41,
    PARTE_2_SALA_2: 42,
    PARTE_2_AYUDANTE_SALA_2: 43,
    PARTE_2_SALA_3: 44,
    PARTE_2_AYUDANTE_SALA_3: 45,

    PARTE_3_SALA_1: 46,
    PARTE_3_AYUDANTE_SALA_1: 47,
    PARTE_3_SALA_2: 48,
    PARTE_3_AYUDANTE_SALA_2: 49,
    PARTE_3_SALA_3: 50,
    PARTE_3_AYUDANTE_SALA_3: 51,

    PARTE_4_SALA_1: 52,
    PARTE_4_AYUDANTE_SALA_1: 53,
    PARTE_4_SALA_2: 54,
    PARTE_4_AYUDANTE_SALA_2: 55,
    PARTE_4_SALA_3: 56,
    PARTE_4_AYUDANTE_SALA_3: 57,

    NVC_1: 8,
    NVC_2: 9,
    NVC_3: 10,

    CONDUCTOR_ESTUDIO_BIBLICO: 11,
    LECTOR_ESTUDIO_BIBLICO: 12,
    ORACION_FINAL: 13
};

export const Sections = {
    GENERAL: 1,
    TREASURES: 2,
    MINISTRY: 3,
    CHRISTIAN_LIVING: 4,
};

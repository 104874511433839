import * as React from 'react';
import IEstudiante from 'types/IEstudiante';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import useEstudiante from 'hooks/useEstudiante';
import IconButton from './IconButton';
import EstudianteForm from 'components/forms/EstudianteForm';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number | null,
}

export default function ModalEstudiante ({ id }: Props): JSX.Element {
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(!modal);
    const { t } = useTranslation();

    if (!id) return <></>;

    const {data: fullProfile} = useEstudiante(id);
    const data = fullProfile as IEstudiante;

    if (!data) return <></>;

    return (
        <>
            <IconButton onClick={toggleModal} icon="fas fa-pencil-alt" />

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg" centered>
                <ModalHeader toggle={toggleModal}>{t('person.edit', 'Editar datos de estudiante')}</ModalHeader>
                <ModalBody>
                    <EstudianteForm data={data} onFinish={toggleModal} />
                </ModalBody>
            </Modal>
        </>
    );
}

import * as React from 'react';
import Box from 'components/layout/Box';
import useEstudiantes from 'hooks/useEstudiantes';
import IEstudiante from 'types/IEstudiante';
import Table from 'components/general/Table';
import SortCaret from 'components/general/SortCaret';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ResponsiveButton from 'components/general/ResponsiveButton';
import DeleteEstudianteButton from 'components/general/DeleteEstudianteButton';
import ProfileButton from 'components/general/ProfileButton';
import ModalEstudiante from 'components/general/ModalEstudiante';
import EstudianteForm from 'components/forms/EstudianteForm';
import {TiposOptions, PrivilegiosOptions, StatusOptions, SexoOptions, Roles} from 'services/constants';
import { getGroupsOptions } from 'services/utils';
import useNumberGroups from 'hooks/useNumberGroups';
import useIsRole from 'hooks/useIsRole';
import ISelectOption from 'types/ISelectOption';
import { useTranslation } from 'react-i18next';

export default function Estudiantes (): JSX.Element {
    const { t } = useTranslation();
    const [modal, setModal] = React.useState(false);
    const { data: estudiantes, isLoading } = useEstudiantes();
    const data = estudiantes as IEstudiante[];
    const numGroups = useNumberGroups();
    const [, setOptionsGrupos] = React.useState(getGroupsOptions(numGroups, false).reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}));
    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const isCoordinador = useIsRole(Roles.COORDINADOR);
    const isSupVmc = useIsRole(Roles.SUP_VMC);

    const canEdit = isAdmin || isCoordinador || isSupVmc;

    const toggleModal = () => setModal(!modal);

    const getGenderIcon = (_: string, row: IEstudiante) => {
        if (row.sexo === 'Masculino'){
            return <span className="pl-3"><i className="fas fa-male pr-3"/></span>;
        }
        return <div className="pl-3"><i className="fas fa-female pr-3"/></div>;
    };

    const getActiveIcon = (cell: number, row: IEstudiante) => {
        if (row.inactivo){
            return <div className=""><i className="fas fa-times"/></div>;
        }
        return <span className=""><i className="fas fa-check"/></span>;
    };

    const getTipo = (_: string, row: IEstudiante) => {
        const status = TiposOptions(t).find((item) => row.tipo === item.value);
        return status ? <div style={{whiteSpace: 'nowrap'}}>{status.label}</div> : null;
    };

    const getPrivilegio = (_: string, row: IEstudiante) => {
        const status = PrivilegiosOptions(t).find((item) => row.privilegio === item.value);
        return status ? <div style={{whiteSpace: 'nowrap'}}>{status.label}</div> : null;
    };

    const getActions = (_: number, row: IEstudiante) => {
        return (
            <div style={{whiteSpace: 'nowrap'}}>
                <ProfileButton id={row.id} icon />
                {canEdit && <ModalEstudiante id={row.id} />}
                {canEdit && <DeleteEstudianteButton id={row.id} />}
            </div>
        );
    };

    const getText = (cell: string) => {
        return (
            <div style={{whiteSpace: 'nowrap'}}>
                {cell}
            </div>
        );
    };

    React.useEffect(() => {
        setOptionsGrupos(getGroupsOptions(numGroups, false).reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}));
    }, [numGroups]);

    const columns = React.useMemo(() => [
        {
            text: '',
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'sexo',
            formatter: getGenderIcon,
            filter: selectFilter({
                options: SexoOptions.reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}),
                placeholder: t('general.all', 'Todos'),
            }),
            headerStyle: () => ({ width: '50px' }),
        },
        {
            text: t('person.name', 'Nombres'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'nombres',
            filter: textFilter({ placeholder: ' ' }),
            formatter: getText,
        },
        {
            text: t('person.lastname', 'Apellidos'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'apellidos',
            filter: textFilter({ placeholder: ' ' }),
            formatter: getText,
        },
        {
            text: t('person.type', 'Tipo'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'tipo',
            formatter: getTipo,
            filter: selectFilter({
                options: TiposOptions(t).reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}),
                placeholder: t('general.all', 'Todos'),
            }),
            align: 'center',
            headerStyle: () => ({ width: '200px' }),
        },
        {
            text: t('person.privilege', 'Privilegio'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'privilegio',
            align: 'center',
            formatter: getPrivilegio,
            filter: selectFilter({
                options: PrivilegiosOptions(t).reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}),
                placeholder: t('general.all', 'Todos'),
            }),
            headerStyle: () => ({ width: '170px' }),
        },
        {
            text: t('person.group', 'Grupo'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'grupo',
            align: 'center',
            filter: selectFilter({
                options: getGroupsOptions(numGroups, false).reduce((obj: any, item: ISelectOption) => ({ ...obj, [item.value]: item.label }), {}),
                placeholder: t('general.all', 'Todos'),
            }),
            headerStyle: () => ({ width: '100px' }),
        },
        {
            text: t('person.status', 'Status'),
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
            dataField: 'inactivo',
            align: 'center',
            filter: selectFilter({
                options: StatusOptions(t).reduce((obj: any, item: any) => ({ ...obj, [item.value]: item.label }), {}),
                placeholder: t('general.all', 'Todos'),
            }),
            formatter: getActiveIcon,
            headerStyle: () => ({ width: '100px' }),
        },
        {
            text: '',
            dataField: 'id',
            formatter: getActions,
            align: 'center',
            headerStyle: () => ({ width: canEdit ? '170px' : '50px'}),
        }
    ], []);

    return (
        <Box title={t('menu.students', 'Estudiantes')}>
            {canEdit && <ResponsiveButton onClick={toggleModal} icon="fas fa-user-plus" />}
            <Table data={data} columns={columns} loading={isLoading} filter={filterFactory()} />

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} centered size="lg">
                <ModalHeader toggle={toggleModal}>{t('person.add-new', 'Agregar nuevo estudiante')}</ModalHeader>
                <ModalBody>
                    <EstudianteForm data={null} onFinish={toggleModal} />
                </ModalBody>
            </Modal>
        </Box>
    );
}

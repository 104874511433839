import * as React from 'react';
import styled from 'styled-components';
import { ModalBody, Modal, ModalFooter, ModalHeader, Row, Col, Button } from 'reactstrap';
import HistorialEstudiante from './HistorialEstudiante';
import TableEstudiantesByCapacity from './TableEstudiantesByCapacity';
import createAsignacion from 'hooks/createAsignacion';
import { useMutation, useQueryClient } from 'react-query';
import MainContext from 'components/layout/MainContext';
import { getMonday, getPartText, getSectionIcon } from 'services/utils';
import IAssignment from 'types/IAssignment';
import usePrograma from 'hooks/useProgram';
import IProgram from 'types/IProgram';
import useCongregationId from 'hooks/useCongregationId';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number
}

export default function AssignButton ({ id }: Props): JSX.Element {
    const { selectedDate } = React.useContext(MainContext);
    const monday = getMonday(selectedDate);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const { data: programData } = usePrograma(monday, currentLanguage);
    const program = programData as IProgram;

    const [modal, setModal] = React.useState(false);
    const [selected, setSelected] = React.useState<number | null>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const queryClient = useQueryClient();

    const congregationId = useCongregationId();

    const toggle = () => {
        setModal(!modal);
        setSelected(null);
    };

    const mutationCreate = useMutation(createAsignacion, {
        onMutate: () => {
            setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['asignments', monday]);
            setIsSubmitting(false);
        },
    });

    const handleSubmit = () => {
        if (selected && congregationId){
            const newData: IAssignment = {
                estudiante_id: selected,
                tipo: id,
                fecha: monday,
                id: -1,
                congregacion_id: congregationId,
            };
            mutationCreate.mutate(newData);
            toggle();
        }
    };

    return (
        <>
            <ButtonStyled onClick={toggle}><i className="fas fa-user-plus" /></ButtonStyled>
            <Modal isOpen={modal} toggle={toggle} keyboard={false} size="xl" centered>
                <ModalHeader toggle={toggle}>
                    <div className="d-flex align-items-center">
                        <img src={getSectionIcon(id)} alt="" width="30px" height="30px" />
                        <Header className="pl-3" dangerouslySetInnerHTML={{ __html: getPartText(t, id, program, true)}} />
                    </div>
                </ModalHeader>
                {modal && (
                    <ModalBody className="p-4">
                        <Row>
                            <Col lg="6">
                                <TableEstudiantesByCapacity capacity={id} setSelected={setSelected} />
                            </Col>
                            <Col lg="6">
                                {selected ?
                                    <HistorialEstudiante id={selected}/>
                                    :
                                    <HistorialEmpty>{t('assign.select-student', 'Seleccione un estudiante')}</HistorialEmpty>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button outline color="primary" onClick={toggle}>{t('assign.cancel', 'Cancelar')}</Button>
                    <Button className="px-5" onClick={handleSubmit} color="primary" type="button" disabled={isSubmitting || !selected}>
                        {t('assign.assign','Asignar')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const ButtonStyled = styled.button`
    display: inline;
    background: none;
    border: none;
`;

const HistorialEmpty = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
`;

const Header = styled.span`
    font-size: 0.9rem;
`;

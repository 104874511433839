import * as React from 'react';
import Table from 'components/general/Table';
import IEstudiante from 'types/IEstudiante';
import styled from 'styled-components';
import SortCaret from 'components/general/SortCaret';
import { format } from 'date-fns';
import useEstudiantesByCapacity from 'hooks/useEstudiantesByCapacity';
import es from 'date-fns/locale/es';
import { t } from 'i18next';

interface Props {
    capacity: number,
    setSelected: (value: number) => void,
}

export default function TableEstudiantesByCapacity ({ capacity, setSelected }: Props): JSX.Element {
    const { data: dataEstudiantes, isLoading } = useEstudiantesByCapacity(capacity);
    const data = dataEstudiantes as IEstudiante[];

    const getFullName = (_: string, row: IEstudiante) => {
        return <>{row.nombres} {row.apellidos}</>;
    };

    const getDate = (_: string, row: IEstudiante) => {
        if (!row.mas_reciente) return '';
        return <>{format(new Date(row.mas_reciente.fecha.replaceAll('-', '/')), 'dd-MMM-yyyy', { locale: es })}</>;
    };

    const columns = React.useMemo(() => [
        {
            text: t('person.name', 'Nombre'),
            dataField: 'nombres',
            formatter: getFullName,
            sort: true,
            sortCaret: (order: string) => SortCaret(order),
        },
        {
            text: t('person.lastname', 'Nombre'),
            dataField: 'apellidos',
            hidden: true,
        },
        {
            text: t('person.more-recent', 'Más reciente'),
            dataField: 'mas_reciente.fecha',
            sort: true,
            formatter: getDate,
            align: 'center',
            sortCaret: (order: string) => SortCaret(order),
            headerStyle: () => ({ width: '150px' }),
        },
    ], []);

    return (
        <Container>
            <Table
                keyField='id'
                data={data}
                columns={columns}
                loading={isLoading}
                selectRow={{
                    mode: 'radio',
                    clickToSelect: true,
                    classes: 'selection-row',
                    hideSelectColumn: true,
                    onSelect: (row: IEstudiante) => {
                        setSelected(row.id);
                    },
                }}
                noPaginate
                rowStyle={{ cursor: 'pointer' }}
                defaultSorted={[{
                    dataField: 'mas_reciente.fecha',
                    order: 'asc'
                }]}
            />
        </Container>
    );
}

const Container = styled.div`
    height: 400px;
    overflow-y: auto;
`;

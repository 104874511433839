import ISession from 'types/ISession';

export const getSession = (): ISession | null => {
    try {
        const value = localStorage.getItem('SESSION');
        return value ? JSON.parse(value) : null;
    } catch (error) {
        return null;
    }
};

export const getCongregationId = (): number | null => {
    try {
        const value = localStorage.getItem('C_ID');
        return value ? parseInt(JSON.parse(value)) : null;
    } catch (error) {
        return null;
    }
};

export const getToken = (): string | null => {
    try {
        const value = localStorage.getItem('SESSION');
        return value ? JSON.parse(value).token : null;
    } catch (error) {
        return null;
    }
};

export const getLanguage = (): string => {
    try {
        const value = localStorage.getItem('locale');
        return value || 'es-US';
    } catch (error) {
        return 'es-US';
    }
};

export const saveSession = (state: ISession | null): void => {
    localStorage.setItem('SESSION', JSON.stringify(state));
};

export const saveCongregationId = (value: number): void => {
    localStorage.setItem('C_ID', JSON.stringify(value));
};

export const saveLanguage = (lang: string): void => {
    localStorage.setItem('locale', lang);
};

export const clearSession = (): void => {
    localStorage.removeItem('SESSION');
};

export const resetSession = (): void => {
    localStorage.clear();
};

import styled from 'styled-components';
import Loader from 'react-spinners/PuffLoader';
import { useTranslation } from 'react-i18next';

export default function MainLoader(): JSX.Element {
    const { t } = useTranslation();
    
    return (
        <LoaderContainer>
            <Loader color="#4a6da7" size={100} />
            <div className="mt-2">{t('app.loading', 'Cargando')}</div>
        </LoaderContainer>
    );
}

const LoaderContainer = styled.div`
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

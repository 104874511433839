import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import ICongregation from 'types/ICongregation';
import useCongregationId from './useCongregationId';

export default function useCongregation(): UseQueryResult {
    const id = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                congregacion(id: "${id}"){
                    id
                    nombre
                    pais
                }
            }
        `);
        return response.congregacion;
    };

    return useQuery<ICongregation, Error>(['congregation', id], loadData);
}


import * as React from 'react';
import {
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import styled from 'styled-components';
import { useIsFetching } from 'react-query';
import Loader from 'react-spinners/PulseLoader';
import MainContext from 'components/layout/MainContext';
import useCurrentUser from 'hooks/useCurrentUser';
import IUser from 'types/IUser';
import { Link } from 'react-router-dom';
import useIsRole from 'hooks/useIsRole';
import { Roles } from 'services/constants';
import UpdatePasswordModal from 'components/forms/UpdatePassword';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function MenuUser (): JSX.Element {
    const [modal, setModal] = React.useState(false);
    const [firstTime, setFirstTime] = React.useState(false);
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const isFetching = useIsFetching();
    const { storeCongregationId, closeSession } = React.useContext(MainContext);
    const {data: userData} = useCurrentUser();
    const user = userData as IUser;

    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const isCoordinador = useIsRole(Roles.COORDINADOR);

    const canEditConfig = isAdmin || isCoordinador;

    const handleCloseSession = () => {
        closeSession();
    };

    const handleCloseModal = () => {
        setModal(false);
        queryClient.invalidateQueries('currentUser');
        setFirstTime(false);
    };

    React.useEffect(() => {
        if (user) {
            setFirstTime(!!user.primera_vez);
        }
    }, [user]);

    if (!user) return <></>;

    const userFullName = user.name;
    storeCongregationId(user.congregacion_id);

    return (
        <MenuContainer>
            <div className="d-flex align-items-center">
                {isFetching ? <div className="pl-2"><Loader color="white" size={5} /></div> : null}
            </div>
            <UncontrolledButtonDropdown>
                <User color="secundary" caret><span>{`${t('general.hello', 'Hola')}, ${userFullName}`}</span></User>
                <DropdownMenu>
                    {canEditConfig && (
                        <DropdownItem className="p-2 px-4">
                            <LinkStyled to="/configuracion"><i className="fas fa-cogs pr-3"/> {t('app.settings', 'Configuración')}</LinkStyled>
                        </DropdownItem>
                    )}
                    <DropdownItem className="p-2 px-4">
                        <ButtonStyled onClick={() => setModal(true)} role="button">
                            <i className="fas fa-unlock-alt pr-4 pl-1" />
                            {t('app.change-password', 'Cambiar contraseña')}
                        </ButtonStyled>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={handleCloseSession} className="p-2 px-4">{t('app.exit', 'Salir')}</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <UpdatePasswordModal isOpen={modal || firstTime} close={handleCloseModal} isClosable={!firstTime} />
        </MenuContainer>
    );
}

const User = styled(DropdownToggle)`
    border: none;
    color: white !important;
    margin-right: 10px;
    height: 50px;

    @media (max-width: 576px){
        margin-right: 0;
    }

    span{
        font-size: 0.9rem;
        padding: 0 10px;
    }

    &:hover{
        color: white;
    }
`;

const MenuContainer = styled.div`
    display: flex;
`;

const LinkStyled = styled(Link)`
    &:hover{
        text-decoration: none;
    }
`;

const ButtonStyled = styled.div`
    border: none;
    background: none;
    width: 100%;
    padding: 0;
    color: ${(props) => props.theme.primary};
`;

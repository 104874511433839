
import { gql } from 'graphql-request';
import API from 'services/api';
import IEstudiante from 'types/IEstudiante';

const deleteEstudiante = async (id: number): Promise<IEstudiante> => {
    return await API.graphql(gql`
        mutation {
            updateEstudiante(
                id: ${id}
                deleted: 1
            ){
                id
            }
        }
    `);
};

export default deleteEstudiante;

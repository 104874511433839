import * as React from 'react';
import styled from 'styled-components';
import IEstudiante from 'types/IEstudiante';
import { Modal, ModalHeader, ModalBody, } from 'reactstrap';
import useEstudiante from 'hooks/useEstudiante';
import IconButton from './IconButton';
import PersonalInformation from './PersonalInformation';
import HistorialEstudiante from 'components/general/HistorialEstudiante';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import InfoCapacities from 'components/general/InfoCapacities';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number | null,
    icon?: boolean,
    className?: string,
}

export default function ProfileButton ({ id, icon, className }: Props): JSX.Element {
    const { t } = useTranslation();
    const [modal, setModal] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState('1');

    const toggleModal = () => {
        setModal(!modal);
        setActiveTab('1');
    };

    const toggle = (tab: string) => setActiveTab(tab);

    if (!id) return <></>;

    const {data: fullProfile} = useEstudiante(id);
    const data = fullProfile as IEstudiante;

    if (!data) return <></>;

    return (
        <>
            {icon ? (
                <IconButton onClick={toggleModal} icon="fas fa-eye" />
            ) : (

                <Button className={className || 'assigned'} style={{color: '#4a6da7'}} onClick={toggleModal}>
                    {data.nombres} {data.apellidos}
                </Button>
            )}

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg" centered>
                <ModalHeader toggle={toggleModal}>
                    {data.sexo === 'Masculino' ? (
                        <i className="fas fa-male pr-3"/>
                    ): (
                        <i className="fas fa-female pr-3"/>
                    )}
                    {data.nombres} <b>{data.apellidos}</b>
                </ModalHeader>
                <ModalBody>
                    <NavStyled tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                <small>{t('person.personal-info', 'Datos personales')}</small>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                <small>{t('person.history', 'Historial')}</small>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                <small>{t('person.capacities', 'Capacidades')}</small>
                            </NavLink>
                        </NavItem>
                    </NavStyled>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="border border-top-0">
                            <PersonalInformation data={data}/>
                        </TabPane>
                        <TabPane tabId="2" className="border border-top-0">
                            {modal && activeTab === '2' && <HistorialEstudiante id={id} />}
                        </TabPane>
                        <TabPane tabId="3" className="border border-top-0">
                            <InfoCapacities data={data.capacidades} isMale={data.sexo === 'Masculino'} />
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        </>
    );
}

const Button = styled.button`
    border: solid #4a6da7 1px;
    color: #4a6da7;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    margin-bottom: 4px;
`;

const NavStyled = styled(Nav)`
    li{
        margin: 0;
    }

    .nav-link{
        color: gray;
    }
`;

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IAssignment from 'types/IAssignment';
import useCongregationId from './useCongregationId';

export default function useAssignments (fecha: string): UseQueryResult {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                asignaciones(fecha: "${fecha}", congregacion_id: ${congregationId}){
                    id
                    tipo
                    estudiante{
                        id
                        nombres
                        apellidos
                    }
                }
            }
        `);
        return response.asignaciones;
    };

    return useQuery<IAssignment[], Error>(['asignments', fecha], loadData);
}

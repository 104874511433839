import { Route, Redirect } from 'react-router-dom';
import useIsSessionOpen from 'hooks/useIsSessionOpen';

interface Props {
    children: React.ReactNode,
    exact: boolean,
    path: string,
}

const PrivateRoute = ({ children, exact, path }: Props): JSX.Element => {
    const isSessionOpen = useIsSessionOpen();

    if (!isSessionOpen) {
        return <Redirect to="/login" />;
    }

    return (
        <Route exact={exact} path={path}>
            {children}
        </Route>
    );
};

export default PrivateRoute;

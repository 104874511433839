import * as React from 'react';
import MainLoader from 'components/general/MainLoader';
import usePrograma from 'hooks/useProgram';
import IProgram from 'types/IProgram';
import CalendarBar from 'components/general/CalendarBar';
import SectionTreasures from 'components/general/SectionTreasures';
import SectionMinistry from 'components/general/SectionMinistry';
import SectionChristianLiving from 'components/general/SectionChristianLiving';
import { Eventos } from 'services/constants';
import 'react-datepicker/dist/react-datepicker.css';
import useEvento from 'hooks/useEvento';
import IEvento from 'types/IEvento';
import MeetingCanceled from 'components/general/MeetingCanceled';
import { getEventoText, getMonday } from 'services/utils';
import MainContext from 'components/layout/MainContext';
import Reports from 'components/forms/Reports';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import SettingsNotas from 'components/forms/SettingsNotas';
import { useTranslation } from 'react-i18next';

export default function Meeting(): JSX.Element {
    const { t, i18n } = useTranslation();
    const { selectedDate, setSelectedDate } = React.useContext(MainContext);
    const monday = getMonday(selectedDate);
    const currentLanguage = i18n.language;

    const isSessionOpen = useIsSessionOpen();

    const { data: programData, isLoading } = usePrograma(monday, currentLanguage);
    const program = programData as IProgram;

    const { data: eventoData, isLoading: isLoadingEvento } = useEvento(monday);
    const event = eventoData as IEvento;

    const isVisit = event && event.tipo === Eventos.VISITA_CIRCUITO;

    return (
        <>
            {isSessionOpen && <Reports />}

            <CalendarBar selectedDate={selectedDate} onChangeDate={setSelectedDate}/>

            {isVisit && <h2>{`- ${getEventoText(Eventos.VISITA_CIRCUITO, t)} -`}</h2>}

            {isLoading || isLoadingEvento ? (
                <MainLoader />
            ) : (
                <>
                    {(event && event.tipo !== Eventos.NOTA && !isVisit) ? (
                        <MeetingCanceled eventType={event.tipo} observations={event.observaciones} />
                    ):(
                        <>
                            {!program ? (
                                <div className="border-bottom p-4 text-center mt-5">{t('app.sorry', 'Lo siento')}<br/>{t('app.no-content', 'Este contenido no existe')}</div>
                            ) : (
                                <div className="program-view">
                                    <SectionTreasures program={program} />
                                    <SectionMinistry program={program} />
                                    <SectionChristianLiving program={program} />
                                    <SettingsNotas />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );}

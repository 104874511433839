import styled from 'styled-components';

interface Props{
    onClick?: () => void,
    disabled?: boolean,
    icon: string,
}

export default function ResponsiveButton ({ onClick, disabled, icon }: Props): JSX.Element {
    return (
        <Container>
            <Button onClick={onClick} disabled={disabled}>
                <i className={icon} />
            </Button>
        </Container>
    );
}

const Container = styled.div`
    text-align: right;

    @media (max-width: 768px){
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 15px;
        right: 15px;
        z-index: 1000;
        background-color: ${(props) => props.theme.primary};
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 0;
        box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.5);
    }
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.primary};
    cursor: pointer;
    font-size: 50px;

    @media (max-width: 768px){
        color: white;
        font-size: 25px;
    }

    &:disabled{
        color: ${(props) => props.theme.primaryLighter};
        cursor: default;
    }
`;

import * as React from 'react';
import styled from 'styled-components';
import { Collapse, ModalBody, Modal, ModalFooter, ModalHeader, Button, Alert } from 'reactstrap';
import Input from 'components/general/Input';
import API from 'services/api';
import { useTranslation } from 'react-i18next';

interface Props {
    isOpen: boolean
    close: () => void
    isClosable: boolean
}

export default function UpdatePassword ({ isOpen, close, isClosable }: Props): JSX.Element {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isOpenCond, setIsOpenCond] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');
    const { t } = useTranslation();

    const [cond1, setCond1] = React.useState(false);
    const [cond2, setCond2] = React.useState(false);
    const [cond3, setCond3] = React.useState(false);
    const [cond4, setCond4] = React.useState(false);

    const clearError = () => setIsError(false);

    React.useMemo(() => {
        setCond1(password.length >= 8 && password.length <= 15);
        setCond2(/\d/.test(password));
        setCond3(/[A-Z]/.test(password));
        setCond4(/[a-z]/.test(password));
    }, [password]);

    const handleSubmit = async (event: React.FormEvent) => {
        setIsSubmitting(true);
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const data = {
            previous: formData.get('prevPassword') as string,
            password: formData.get('newPassword') as string,
        };

        try{
            const response = await API.updatePassword(data);
            if (response?.data?.result){
                setCond1(false);
                setCond2(false);
                setCond3(false);
                setCond4(false);
                close();
            }else{
                setIsError(true);
            }
        }catch(error){
            if (error.response.status === 403){
                setIsError(true);
            }else{
                console.log('error', error);
            }
        }finally{
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        setCond1(false);
        setCond2(false);
        setCond3(false);
        setCond4(false);
        close();
    };

    const nothing = () => { /* Nothing */ };

    const isOk = cond1 && cond2 && cond3 && cond4 && password === repeatPassword && oldPassword.length >= 3;

    return (
        <Modal isOpen={isOpen} toggle={isClosable ? handleClose : nothing} size="sm" centered>
            {isClosable ?
                <ModalHeader toggle={handleClose}>{t('settings.change-password', 'Cambiar contraseña')}</ModalHeader>
                :
                <ModalHeader>{t('settings.change-password', 'Cambiar contraseña')}</ModalHeader>
            }
            <form onSubmit={handleSubmit}>
                <ModalBody className="p-4">
                    <Input name="prevPassword" label={t('settings.current-password', 'Contraseña actual')} type="password" onChange={(value) => {clearError(); setOldPassword(value);}} />
                    <Input
                        name="newPassword"
                        label={t('program.new-password', 'Contraseña nueva')}
                        type="password"
                        onChange={(value) => setPassword(value)}
                        onFocus={()=> {setIsOpenCond(true);}}
                        onBlur={()=> {setIsOpenCond(false);}}
                    />
                    <Collapse isOpen={isOpenCond}>
                        <div className="pb-4">
                            <Condition value={cond1}>{t('settings.condition1', '8 a 15 caracteres')}</Condition>
                            <Condition value={cond2}>{t('settings.condition2', 'Al menos 1 número')}</Condition>
                            <Condition value={cond3}>{t('settings.condition3', 'Al menos 1 Mayúscula')}</Condition>
                            <Condition value={cond4}>{t('settings.condition4', 'Al menos 1 minúscula')}</Condition>
                        </div>
                    </Collapse>
                    <Input name="repeatNewPassword" label={t('settings.repeat-new-password', 'Repetir contraseña nueva')} type="password" onChange={(value) => setRepeatPassword(value)} />
                    {isError && <Alert color="danger" className="mt-4 mb-0">{t('settings.wrong-data', 'Datos incorrectos')}</Alert>}
                </ModalBody>
                <ModalFooter>
                    {isClosable && <Button outline color="primary" onClick={handleClose} disabled={isSubmitting}>{t('general.cancel', 'Cancelar')}</Button>}
                    <Button className="px-5" color="primary" disabled={!isOk || isSubmitting}>
                        {t('settings.change', 'Cambiar')}
                    </Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

interface PropStyle {
    value: boolean
}

const Condition = styled.div`
    font-size: 13px;
    &::before{
        content: "${(props: PropStyle) => (props.value ? '✔' : '×')} ";
        color: ${(props: PropStyle) => (props.value ? 'green' : 'red')};
    }
`;

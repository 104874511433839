import { Link } from 'react-router-dom';
import iconBack from 'assets/iconBack.png';
import { useTranslation } from 'react-i18next';

export default function LinkBack (): JSX.Element {
    const { t } = useTranslation();

    return (
        <Link to="/" className="d-flex align-items-center">
            <img src={iconBack} alt="back" width="40px"/>
            <span className="px-2">{t('general.go-home', 'Ir al inicio')}</span>
        </Link>
    );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import TableXN from 'table-xn';
import { useTranslation } from 'react-i18next';

export default function Table ({ ...props }) {
    const { t } = useTranslation();

    const labels = {
        noData: t('table.empty', 'Sin datos'),
        showing: t('table.showing', 'Mostrando'),
        show: t('table.show', 'Mostrar'),
        to: t('table.from', 'a'),
        of: t('table.of', 'de'),
    };

    return <TableXN labels={labels} color="#4a6da7" {...props} />;
}

import * as React from 'react';
import Box from 'components/layout/Box';
import Table from 'components/general/Table';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ResponsiveButton from 'components/general/ResponsiveButton';
import useUsers from 'hooks/useUsers';
import IUser from 'types/IUser';
import UserForm from 'components/forms/UserForm';
import DeleteUserButton from 'components/general/DeleteUserButton';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { getRoleName } from 'services/utils';
import useIsRole from 'hooks/useIsRole';
import { Roles } from 'services/constants';
import { useTranslation } from 'react-i18next';

export default function Users (): JSX.Element {
    const [modal, setModal] = React.useState(false);
    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const { data: users, isLoading } = useUsers();
    const data = users as IUser[];
    const toggleModal = () => setModal(!modal);
    const { t } = useTranslation();

    const getActions = (_: number, row: IUser) => {
        return (
            <>
                <DeleteUserButton id={row.id} />
            </>
        );
    };

    const getDateTime = (_: Date, row: IUser) => {
        if (row.ultimoacceso && !row.primera_vez){
            try{
                return <small>{getNoWrap(format(new Date(row.ultimoacceso), 'dd-MM-yyyy hh:mm', { locale: es }))}</small>;
            }catch{
                return '';
            }
        }
        return '';
    };

    const getRole = (_: number, row: IUser) => {
        return getNoWrap(getRoleName(row.rol, t));
    };

    const getNoWrap = (value: string) => {
        return <span style={{whiteSpace: 'nowrap'}}>{value}</span>;
    };

    const columns = React.useMemo(() => [
        {
            text: t('user.name', 'Nombre'),
            dataField: 'name',
            formatter: getNoWrap,
        },
        {
            text: t('user.lastname', 'Apellido'),
            dataField: 'lastname',
            formatter: getNoWrap,
        },
        {
            text: t('user.email', 'Correo electrónico'),
            dataField: 'email',
            formatter: getNoWrap,
        },
        {
            text: t('user.last-access', 'Último acceso'),
            dataField: 'ultimoacceso',
            formatter: getDateTime,
        },
        {
            text: t('user.role', 'Rol'),
            dataField: 'rol',
            formatter: getRole,
        },
        {
            text: t('user.congregation', 'Congregación'),
            dataField: 'congregacion.nombre',
            hidden: !isAdmin,
            formatter: getNoWrap,
        },
        {
            text: '',
            dataField: 'id',
            formatter: getActions,
            align: 'center',
            headerStyle: () => ({ width: '80px' }),
        }
    ], []);

    return (
        <Box title="Usuarios">
            <ResponsiveButton onClick={toggleModal} icon="fas fa-user-plus" />
            <Table data={data} columns={columns} loading={isLoading} noPaginate />

            <Modal isOpen={modal} toggle={toggleModal} keyboard={false} size="lg">
                <ModalHeader toggle={toggleModal}>{t('user.add-new', 'Agregar usuario')}</ModalHeader>
                <ModalBody>
                    <UserForm data={null} onFinish={toggleModal}/>
                </ModalBody>
            </Modal>
        </Box>
    );
}

import * as React from 'react';
import IProgram from 'types/IProgram';
import iconChristianLiving from 'assets/iconChristianLiving.png';
import { Col, Row } from 'reactstrap';
import SectionTitle from '../layout/SectionTitle';
import { Kind } from 'services/constants';
import useEvento from 'hooks/useEvento';
import IEvento from 'types/IEvento';
import { Eventos } from 'services/constants';
import { getMonday } from 'services/utils';
import MainContext from 'components/layout/MainContext';
import Assignment from './Assignment';
import { useTranslation } from 'react-i18next';

interface Props {
    program: IProgram,
}

export default function SectionChristianLiving ({ program }: Props): JSX.Element {
    const { selectedDate } = React.useContext(MainContext);
    const monday = getMonday(selectedDate);
    const { t } = useTranslation();

    const { data: eventoData } = useEvento(monday);
    const evento = eventoData as IEvento;

    const isVisit = evento && evento.tipo === Eventos.VISITA_CIRCUITO;

    return (
        <section>
            <SectionTitle text={t('title.living-christians', 'Nuestra vida cristiana')} className="vmc-christian-living" icon={iconChristianLiving} />
            <div><strong>{t('program.song-prayer', 'Canción {{song}} y oración', { song: program.cancion2 })}</strong></div>

            <Row>
                <Col md="8" className="mb-1"><strong><span dangerouslySetInnerHTML={{ __html: program.nvc1 }} /></strong> ({program.nvc1_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.nvc1_fuente }} /></Col>
                <Col md="4"><Assignment type={Kind.NVC_1} /></Col>
            </Row>

            {!!program.nvc2?.length && (
                <Row>
                    <Col md="8" className="mb-1"><strong><span dangerouslySetInnerHTML={{ __html: program.nvc2 }} /></strong> ({program.nvc2_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.nvc2_fuente }} /></Col>
                    <Col md="4"><Assignment type={Kind.NVC_2} /></Col>
                </Row>
            )}
            {!!program.nvc3?.length && (
                <Row>
                    <Col md="8" className="mb-1"><strong><span dangerouslySetInnerHTML={{ __html: program.nvc3 }} /></strong> ({program.nvc3_duracion} mins.): <span dangerouslySetInnerHTML={{ __html: program.nvc3_fuente }} /></Col>
                    <Col md="4"><Assignment type={Kind.NVC_3} /></Col>
                </Row>
            )}
            {!isVisit && (
                <Row>
                    <Col md="8" className="mb-1"><strong>{t('program.bible-study', 'Estudio bíblico de la congregación')} </strong>(30 mins.): <span dangerouslySetInnerHTML={{ __html: program.estudio_biblico }} /></Col>
                    <Col md="4">
                        <Assignment type={Kind.CONDUCTOR_ESTUDIO_BIBLICO} />
                        <Assignment type={Kind.LECTOR_ESTUDIO_BIBLICO} isAux />
                    </Col>
                </Row>
            )}
            <div><strong>{t('program.concluding-comments', 'Palabras de conclusión')} </strong>(3 mins.)</div>
            <Row>
                <Col md="8" className="mb-1"><strong>{t('program.song-prayer', 'Canción {{song}} y oración', { song: program.cancion3 })}</strong></Col>
                <Col md="4"><Assignment type={Kind.ORACION_FINAL} /></Col>
            </Row>
        </section>
    );
}

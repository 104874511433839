import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Page404(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <h1 className="border-bottom p-4">{t('error.404', 'Lo siento. Este contenido no existe')}</h1>
            <Link to="/" className="p-4">{t('app.go-home', 'Ir al inicio')}</Link>
        </div>
    );
}

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IConfiguracion from 'types/IConfiguracion';
import useCongregationId from 'hooks/useCongregationId';

const useConfiguracion = (key: string): UseQueryResult => {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                configuracion(congregacion_id: ${congregationId}, key: "${key}"){
                    id
                    key
                    value
                    congregacion_id
                }
            }
        `);
        return response.configuracion;
    };

    return useQuery<IConfiguracion, Error>(['configuracion', key], loadData);
};

export default useConfiguracion;

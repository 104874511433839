import { gql } from 'graphql-request';
import API from 'services/api';
import ISala from 'types/ISala';

const updateSala = async (data: ISala): Promise<ISala> => {
    return await API.graphql(gql`
        mutation {
            updateSala(
                id: ${data.id}
                activa: ${data.activa ? 1 : 0}
            ){
                id
            }
        }
    `);
};

export default updateSala;

import { getFirstMonday, getMonday } from 'services/utils';
import IAssignment from 'types/IAssignment';
import IEvento from 'types/IEvento';
import IProgram from 'types/IProgram';
import IReportData from 'types/IReportData';
import useAssignments from './useAssignments';
import useEvento from './useEvento';
import usePrograma from './useProgram';
import { add } from 'date-fns';

const useReportData = (month: Date, language: string): IReportData[] => {
    const array = [];

    const firstMonday = getFirstMonday(month);

    for (let ii = 0; ii < 5; ii += 1){
        const monday = getMonday(add(firstMonday, { days: 7 * ii }));
        const { data: programData } = usePrograma(monday, language);
        const program = programData as IProgram;

        const { data: assignmentsData } = useAssignments(monday);
        const assignments = assignmentsData as IAssignment[];

        const { data: eventoData } = useEvento(monday);
        const event = eventoData as IEvento;
        array.push({ date: add(firstMonday, { days: 7 * ii }), program, assignments, event });
    }
    return array.filter((item) => item.date.getMonth() === month.getMonth());
};

export default useReportData;

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { clearHtml, findAssignedName, getDateRangeFormatted, getEventoText, getSalaName, isSalaActive } from 'services/utils';
import ISala from 'types/ISala';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import IAssignment from 'types/IAssignment';
import { Eventos, Kind } from 'services/constants';
import ICongregation from 'types/ICongregation';
import IReportData from 'types/IReportData';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 35,
        paddingHorizontal: 35,
        fontSize: 10,
    },
    week: {
        fontSize: 18,
        fontWeight: 700,
        marginBottom: '4mm',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subtitle: {
        fontSize: 12,
        marginTop: '3mm',
        marginBottom: '1mm',
    },
    header: {
        fontSize: 10,
        textAlign: 'right',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        left: 0,
        right: 30,
        textAlign: 'right',
        color: 'grey',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 10,
        marginBottom: '1mm',
    },
    sectionTitle: {
        color: 'white',
        padding: '3px',
        textTransform: 'uppercase',
        fontSize: 10,
        marginTop: '3mm',
        marginBottom: '2mm',
    },
    event: {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: '3mm',
        textAlign: 'center',
    }
});
interface Props {
    data: IReportData[]
    congregation: ICongregation
    salas: ISala[]
    color: boolean
}

export default function Pdf({ data, congregation, salas, color }: Props): JSX.Element{
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const sala2Name = getSalaName(2, salas);
    const sala3Name = getSalaName(3, salas);

    const month = format(data[0]?.date, 'MMMM', { locale: es }).toUpperCase();
    const year = data[0]?.date.getFullYear();

    const getSingleRow = (text: string, kind: number, duration: number | null, assignments: IAssignment[]) => {
        if (!text) return <></>;

        return (
            <View style={styles.row}>
                <Text>{`${text} ${duration ? `(${duration} mins.)` : ''}`}</Text>
                <Text>{findAssignedName(kind, assignments)}</Text>
            </View>
        );
    };

    const getRow = (text: string, kind1: number, kind2: number, duration: number, assignments: IAssignment[]) => {
        if (!text) return <></>;

        const student1 = findAssignedName(kind1, assignments);
        const student2 = findAssignedName(kind2, assignments);
        return (
            <View style={styles.row}>
                <Text>{text} ({duration} mins.)</Text>
                <Text>
                    {student1 || ''}
                    {student2 ? <Text style={{color: 'gray'}}>{` / ${student2}`}</Text> : ''}
                </Text>
            </View>
        );
    };

    const getCanceled = (eventType: number, observations: string) => {
        if (eventType === Eventos.OTRO){
            return <Text style={styles.event}>{`- ${observations} -`}</Text>;
        }
        return <Text style={styles.event}>{`- ${getEventoText(eventType, t)} -`}</Text>;
    };

    return (
        <Document>
            <Page style={styles.body} size="LETTER">
                <Text style={styles.header} fixed>{t('report.title', 'Reunión Vida y Ministerio Cristianos | {{month}} {{year}} - Congregacion {{congr}}', { month, year, congr: congregation?.nombre })}</Text>

                {data.map((item) => {
                    if (!item.program) return <></>;

                    const smm1 = clearHtml(item.program.smm1);
                    const smm2 = clearHtml(item.program.smm2);
                    const smm3 = clearHtml(item.program.smm3);
                    const smm4 = clearHtml(item.program.smm4);
                    const nvc1 = clearHtml(item.program.nvc1);
                    const nvc2 = clearHtml(item.program.nvc2);
                    const nvc3 = clearHtml(item.program.nvc3);

                    const isVisit = item.event && item.event.tipo === Eventos.VISITA_CIRCUITO;
                    return (
                        <View key={item.date.toString()} wrap={false}>
                            <View style={{borderTop: '1pt', borderTopColor: 'gray', borderStyle: 'solid', marginTop: '3mm'}} />

                            <Text style={{ marginTop: '3mm', fontSize: 8 }}>{t('program.week-from', 'SEMANA DEL')}</Text>

                            <View style={styles.week}>
                                <Text>{getDateRangeFormatted(new Date(item.date), currentLanguage)}</Text>
                                {(!item.event || isVisit) && (
                                    <Text style={{color: 'gray'}}>{t('program.chairman', 'Presidente')}: {findAssignedName(Kind.PRESIDENCIA, item.assignments)}</Text>
                                )}
                            </View>
                            {isVisit && <Text style={styles.event}>{`- ${getEventoText(Eventos.VISITA_CIRCUITO, t)} -`}</Text>}

                            {item.event && item.event.tipo !== Eventos.NOTA && !isVisit ? (
                                <>
                                    {getCanceled(item.event.tipo, item.event.observaciones)}
                                </>
                            ):(
                                <>
                                    {getSingleRow(t('report.song-prayer', 'Canción {{song}} y oración', { song: item.program.cancion1 }), Kind.ORACION_INICIO, null, item.assignments)}
                                    {item.event && item.event.tipo === Eventos.NOTA && <Text style={{textAlign: 'right', fontSize: 9}}>{item.event.observaciones}</Text>}
                                    <Text style={[styles.sectionTitle, { backgroundColor: color ? '#656164' : '#A0A0A0' }]}>{t('title.treasures', 'Tesoros de la biblia')}</Text>
                                    {getSingleRow(clearHtml(item.program.discurso), Kind.DISCURSO, 10, item.assignments)}
                                    {getSingleRow(t('program.spiritual-gems', 'Busquemos perlas escondidas'), Kind.PERLAS, 10, item.assignments)}

                                    {existSala2 && <Text style={{marginTop: '3mm'}}>{getSingleRow(t('report.in-charge', 'Encargado {{sala}}', { sala: sala2Name }), Kind.ENCARGADO_SALA_2, null, item.assignments)}</Text>}
                                    {existSala2 && <>{getSingleRow(t('report.in-charge', 'Encargado {{sala}}', { sala: sala3Name }), Kind.ENCARGADO_SALA_3, null, item.assignments)}</>}

                                    <View style={{marginTop: '3mm'}}>{getSingleRow(t('report.reading', 'Lectura'), Kind.LECTURA_SALA_1, 4, item.assignments)}</View>
                                    {existSala2 && <>{getSingleRow(t('report.reading-hall', 'Lectura - {{sala}}', { sala: sala2Name }), Kind.LECTURA_SALA_2, 4, item.assignments)}</>}
                                    {existSala3 && <>{getSingleRow(t('report.reading-hall', 'Lectura - {{sala}}', { sala: sala3Name }), Kind.LECTURA_SALA_3, 4, item.assignments)}</>}

                                    <Text style={[styles.sectionTitle, { backgroundColor: color ? '#a56803' : '#A0A0A0' }]}>{t('title.apply-ministry', 'Seamos mejores maestros')}</Text>
                                    {getRow(smm1, Kind.PARTE_1_SALA_1, Kind.PARTE_1_AYUDANTE_SALA_1, item.program.smm1_duracion, item.assignments)}
                                    {getRow(smm2, Kind.PARTE_2_SALA_1, Kind.PARTE_2_AYUDANTE_SALA_1, item.program.smm2_duracion, item.assignments)}
                                    {getRow(smm3, Kind.PARTE_3_SALA_1, Kind.PARTE_3_AYUDANTE_SALA_1, item.program.smm3_duracion, item.assignments)}
                                    {getRow(smm4, Kind.PARTE_4_SALA_1, Kind.PARTE_4_AYUDANTE_SALA_1, item.program.smm4_duracion, item.assignments)}

                                    {existSala2 && (
                                        <>
                                            <Text style={styles.subtitle}>{sala2Name}</Text>
                                            {getRow(smm1, Kind.PARTE_1_SALA_2, Kind.PARTE_1_AYUDANTE_SALA_2, item.program.smm1_duracion, item.assignments)}
                                            {getRow(smm2, Kind.PARTE_2_SALA_2, Kind.PARTE_2_AYUDANTE_SALA_2, item.program.smm2_duracion, item.assignments)}
                                            {getRow(smm3, Kind.PARTE_3_SALA_2, Kind.PARTE_3_AYUDANTE_SALA_2, item.program.smm3_duracion, item.assignments)}
                                            {getRow(smm4, Kind.PARTE_4_SALA_2, Kind.PARTE_4_AYUDANTE_SALA_2, item.program.smm4_duracion, item.assignments)}
                                        </>
                                    )}

                                    {existSala3 && (
                                        <>
                                            <Text style={styles.subtitle}>{sala3Name}</Text>
                                            {getRow(smm1, Kind.PARTE_1_SALA_3, Kind.PARTE_1_AYUDANTE_SALA_3, item.program.smm1_duracion, item.assignments)}
                                            {getRow(smm2, Kind.PARTE_2_SALA_3, Kind.PARTE_2_AYUDANTE_SALA_3, item.program.smm2_duracion, item.assignments)}
                                            {getRow(smm3, Kind.PARTE_3_SALA_3, Kind.PARTE_3_AYUDANTE_SALA_3, item.program.smm3_duracion, item.assignments)}
                                            {getRow(smm4, Kind.PARTE_4_SALA_3, Kind.PARTE_4_AYUDANTE_SALA_3, item.program.smm4_duracion, item.assignments)}
                                        </>
                                    )}
                                    <Text style={[styles.sectionTitle, { backgroundColor: color ? '#99131e': '#A0A0A0' }]}>{t('title.living-christians', 'Nuestra vida cristiana')}</Text>
                                    <Text>{t('report.song', 'Canción {{song}}', { song: item.program.cancion2 })}</Text>

                                    <View style={{marginTop: '3mm'}}>
                                        {getSingleRow(nvc1, Kind.NVC_1, item.program.nvc1_duracion, item.assignments)}
                                    </View>
                                    {getSingleRow(nvc2, Kind.NVC_2, item.program.nvc2_duracion, item.assignments)}
                                    {getSingleRow(nvc3, Kind.NVC_3, item.program.nvc3_duracion, item.assignments)}

                                    {!isVisit && (
                                        <View style={{marginTop: '3mm'}}>
                                            {getRow(t('program.bible-study', 'Estudio bíblico de la congregación'), Kind.CONDUCTOR_ESTUDIO_BIBLICO, Kind.LECTOR_ESTUDIO_BIBLICO, 30, item.assignments)}
                                        </View>
                                    )}

                                    <View style={{marginTop: '3mm'}}>
                                        {getSingleRow(t('report.song-prayer', 'Canción {{song}} y oración', { song: item.program.cancion3 }), Kind.ORACION_FINAL, null, item.assignments)}
                                    </View>
                                </>
                            )}
                        </View>
                    );
                })}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
}

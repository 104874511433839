import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getLanguage } from 'services/session';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: getLanguage(),
        fallbackLng: 'es-US',
        backend: {
            loadPath: './locales/{{lng}}.json',
        },
    });

export default i18n;

import Select from 'components/general/Select';
import { getGroupsOptions } from 'services/utils';
import { MAX_GROUPS } from 'services/constants';
import { useMutation, useQueryClient } from 'react-query';
import useConfiguracion from 'hooks/useConfiguracion';
import IConfiguracion from 'types/IConfiguracion';
import updateConfiguracion from 'hooks/updateConfiguracion';
import { useTranslation } from 'react-i18next';

export default function SettingsGroups (): JSX.Element {
    const {data: configData} = useConfiguracion('GRUPOS');
    const configGroups = configData as IConfiguracion;
    const { t } = useTranslation();

    const queryClient = useQueryClient();

    const mutationUpdateConfig = useMutation(updateConfiguracion, {
        onMutate: () => {
            // setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries(['configuracion', 'GRUPOS']);
            // setIsSubmitting(false);
        },
    });

    const handleGroupsChange = (value: string | number) => {
        mutationUpdateConfig.mutate({ ...configGroups, value: value.toString() });
    };
    return (
        <Select
            name="grupos"
            label={t('settings.groups', 'Cantidad de grupos')}
            value={configGroups?.value}
            onChange={handleGroupsChange}
            options={getGroupsOptions(MAX_GROUPS)}
        />
    );
}

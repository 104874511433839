import { gql } from 'graphql-request';
import API from 'services/api';
import IConfiguracion from 'types/IConfiguracion';

const updateConfiguracion = async (data: IConfiguracion): Promise<IConfiguracion> => {
    return await API.graphql(gql`
        mutation {
            updateConfiguracion(
                id: ${data.id}
                value: "${data.value}"
            ){
                id
            }
        }
    `);
};

export default updateConfiguracion;

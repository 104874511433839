import { Col } from 'reactstrap';

interface Props {
    label: string,
    value: string,
    size?: string,
}

export default function InfoLabel ({ label, value, size }: Props): JSX.Element {
    if (!value?.length) {
        return <></>;
    }

    return (
        <Col md={size} className="pb-4">
            <div><small><b>{label}</b></small></div>
            <div className="pl-2">{value}</div>
        </Col>
    );
}

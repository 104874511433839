import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IEstudiante from 'types/IEstudiante';

export default function useEstudiante (id: number): UseQueryResult {
    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                estudiante(id: "${id}"){
                    id
                    nombres
                    apellidos
                    sexo
                    fecha_nacimiento
                    fecha_bautizado
                    fecha_matriculado
                    telefono
                    email
                    observaciones
                    inactivo
                    grupo
                    direccion1
                    direccion2
                    tipo
                    privilegio
                    capacidades
                }
            }
        `);

        return response.estudiante;
    };

    return useQuery<IEstudiante, Error>(['Estudiante', id], loadData);
}

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IAssignment from 'types/IAssignment';

export default function useAssignmentsEstudiante (estudianteId: number): UseQueryResult {
    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                asignacionesEstudiante(estudiante_id: ${estudianteId}){
                    id
                    fecha
                    tipo
                    programa{
                        lectura_semanal
                        discurso
                        busquemos_perlas
                        lectura
                        smm1
                        smm2
                        smm3
                        smm4
                        nvc1
                        nvc2
                        nvc3
                        estudio_biblico
                    }
                }
            }
        `);
        return response.asignacionesEstudiante;
    };

    return useQuery<IAssignment[], Error>(['asignments', estudianteId], loadData);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { GraphQLClient } from 'graphql-request';
import { clearSession, getToken } from 'services/session';

const baseURL = 'https://vmcserver.vizrsoft.com';

const graphQLClient = new GraphQLClient(`${baseURL}/graphql`);

const API = {
    login: async (data: {email: string, password: string}) => axios.post(`${baseURL}/login`, data),
    getCurrentUser: async () => axios.get(`${baseURL}/mydata`, { headers: {'authorization' : `Bearer ${getToken()}`} }),
    updatePassword: async (data: {previous: string, password: string}) => axios.post(`${baseURL}/reset`, data, { headers: {'authorization' : `Bearer ${getToken()}`} }),
    graphql: async (query: string) => {
        graphQLClient.setHeader('authorization', `Bearer ${getToken()}`);
        try {
            const response = await graphQLClient.request(query);
            return response;
        }catch(e: any){
            if (e.response.status === 403) {
                clearSession();
                window.location.replace('/');
            }
        }
    },
};

export default API;

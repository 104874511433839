import * as React from 'react';
import { getSalaName, isSalaActive } from 'services/utils';
import useSalas from 'hooks/useSalas';
import ISala from 'types/ISala';
import { Button } from 'reactstrap';
import { Kind } from 'services/constants';
import { useTranslation } from 'react-i18next';

interface Props {
    data: string,
    isMale: boolean,
    onChange: (value: string) => void,
}

export default function Capacities ({ data, isMale, onChange }: Props): JSX.Element {
    const initialData = data?.split('|') || [];
    const [capacities, setCapacities] = React.useState(initialData);
    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];
    const { t } = useTranslation();

    if (!salas) return <></>;

    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const sala1Name = getSalaName(1, salas);
    const sala2Name = getSalaName(2, salas);
    const sala3Name = getSalaName(3, salas);
    const existExtraSalas = existSala2 || existSala3;


    const addCapacity = (id: string) => {
        const array = [...capacities];
        array.push(id);
        setCapacities(array);
        onChange(array.join('|'));
    };

    const removeCapacity = (id: string) => {
        const array = capacities.filter((item) => item !== id);
        setCapacities(array);
        onChange(array.join('|'));
    };

    const handleChange = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        if (target.checked){
            addCapacity(target.name);
        }else{
            removeCapacity(target.name);
        }
    };

    const getCheckbox = (value: number, label: string) => {
        return (
            <div>
                <div className="pretty p-default">
                    <input type="checkbox" name={value.toString()} checked={capacities.some((o) => o === value.toString())} onChange={handleChange} />
                    <div className="state p-primary">
                        <label>{label}</label>
                    </div>
                </div>
            </div>
        );
    };

    const checkAll = () => {
        let array: number[] = [
            Kind.PARTE_1_SALA_1, Kind.PARTE_1_AYUDANTE_SALA_1,
            Kind.PARTE_2_SALA_1, Kind.PARTE_2_AYUDANTE_SALA_1,
            Kind.PARTE_3_SALA_1, Kind.PARTE_3_AYUDANTE_SALA_1,
            Kind.PARTE_4_SALA_1, Kind.PARTE_4_AYUDANTE_SALA_1];

        if (isMale) {
            array = [...array,
                Kind.PRESIDENCIA, Kind.ORACION_INICIO, Kind.ORACION_FINAL,
                Kind.DISCURSO, Kind.PERLAS, Kind.LECTURA_SALA_1,
                Kind.NVC_1, Kind.NVC_2, Kind.NVC_3,
                Kind.CONDUCTOR_ESTUDIO_BIBLICO, Kind.LECTOR_ESTUDIO_BIBLICO
            ];
        }

        if (existSala2) {
            if (isMale){
                array = [...array, Kind.LECTURA_SALA_2, Kind.ENCARGADO_SALA_2];
            }
            array = [...array,
                Kind.PARTE_1_SALA_2, Kind.PARTE_1_AYUDANTE_SALA_2,
                Kind.PARTE_2_SALA_2, Kind.PARTE_2_AYUDANTE_SALA_2,
                Kind.PARTE_3_SALA_2, Kind.PARTE_3_AYUDANTE_SALA_2,
                Kind.PARTE_4_SALA_2, Kind.PARTE_4_AYUDANTE_SALA_2
            ];
        }

        if (existSala3) {
            if (isMale){
                array = [...array, Kind.LECTURA_SALA_3, Kind.ENCARGADO_SALA_3];
            }
            array = [...array,
                Kind.PARTE_1_SALA_3, Kind.PARTE_1_AYUDANTE_SALA_3,
                Kind.PARTE_2_SALA_3, Kind.PARTE_2_AYUDANTE_SALA_3,
                Kind.PARTE_3_SALA_3, Kind.PARTE_3_AYUDANTE_SALA_3,
                Kind.PARTE_4_SALA_3, Kind.PARTE_4_AYUDANTE_SALA_3
            ];
        }

        setCapacities(array.map((item) => item.toString()));
        onChange(array.join('|'));
    };

    const uncheckAll = () => {
        setCapacities([]);
        onChange('');
    };

    return (
        <div className="p-4">
            <div className="float-right mb-2">
                <Button color="primary" onClick={checkAll} type="button" size="sm" className="mx-2">{t('capacities.check-all', 'Marcar todo')}</Button>
                <Button color="primary" onClick={uncheckAll} type="button" size="sm">{t('capacities.uncheck-all', 'Desmarcar todo')}</Button>
            </div>
            {isMale && (
                <>
                    <div><b>{t('program.general', 'General')}</b></div>
                    <div className="px-4">
                        {getCheckbox(Kind.PRESIDENCIA, t('program.chairman', 'Presidente'))}
                        {getCheckbox(Kind.ORACION_INICIO, t('program.initial-prayer', 'Oracion inicial'))}
                        {getCheckbox(Kind.ORACION_FINAL, t('program.ending-prayer', 'Oracion final'))}
                        {existSala2 && getCheckbox(Kind.ENCARGADO_SALA_2, t('program.in-charge', 'Encargado {{sala}}', { sala: sala2Name }))}
                        {existSala3 && getCheckbox(Kind.ENCARGADO_SALA_3, t('program.in-charge', 'Encargado {{sala}}', { sala: sala3Name }))}
                    </div>

                    <div className="mt-4"><b>{t('title.treasures', 'Tesoros de la biblia')}</b></div>
                    <div className="px-4 mb-4">
                        {getCheckbox(Kind.DISCURSO, t('program.speach', 'Discurso'))}
                        {getCheckbox(Kind.PERLAS, t('program.spiritual-gems', 'Busquemos perlas'))}
                        <div className="mt-2">{getCheckbox(Kind.LECTURA_SALA_1, 'Lectura estudiantil')}</div>
                        {existSala2 && getCheckbox(Kind.LECTURA_SALA_2, t('program.reading', 'Lectura estudiantil {{sala}}', { sala: sala2Name}))}
                        {existSala3 && getCheckbox(Kind.LECTURA_SALA_3, t('program.reading', 'Lectura estudiantil {{sala}}', { sala: sala3Name}))}
                    </div>
                </>
            )}

            <div><b>{t('title.apply-ministry', 'Seamos mejores maestros')}</b></div>
            <div className="px-4">
                {existExtraSalas && <div><i>{sala1Name}</i></div>}
                {getCheckbox(Kind.PARTE_1_SALA_1, t('program.part', 'Parte {{n}}', { n: 1 }))}
                {getCheckbox(Kind.PARTE_1_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                <div className="mt-2">{getCheckbox(Kind.PARTE_2_SALA_1, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                {getCheckbox(Kind.PARTE_2_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                <div className="mt-2">{getCheckbox(Kind.PARTE_3_SALA_1, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                {getCheckbox(Kind.PARTE_3_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 3 }))}
                <div className="mt-2">{getCheckbox(Kind.PARTE_4_SALA_1, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                {getCheckbox(Kind.PARTE_4_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
            </div>

            {existSala2 && (
                <div className="px-4 pt-4">
                    <div><i>{sala2Name}</i></div>
                    {getCheckbox(Kind.PARTE_1_SALA_2, t('program.part', 'Parte {{n}}', { n: 1 }))}
                    {getCheckbox(Kind.PARTE_1_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_2_SALA_2, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                    {getCheckbox(Kind.PARTE_2_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_3_SALA_2, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                    {getCheckbox(Kind.PARTE_3_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 3 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_4_SALA_2, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                    {getCheckbox(Kind.PARTE_4_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
                </div>
            )}

            {existSala3 && (
                <div className="px-4 pt-4">
                    <div><i>{sala3Name}</i></div>
                    {getCheckbox(Kind.PARTE_1_SALA_3, t('program.part', 'Parte {{n}}', { n: 1 }))}
                    {getCheckbox(Kind.PARTE_1_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_2_SALA_3, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                    {getCheckbox(Kind.PARTE_2_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_3_SALA_3, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                    {getCheckbox(Kind.PARTE_3_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 3 }))}
                    <div className="mt-2">{getCheckbox(Kind.PARTE_4_SALA_3, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                    {getCheckbox(Kind.PARTE_4_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
                </div>
            )}

            {isMale && (
                <>
                    <div className="mt-4"><b>{t('title.living-christians', 'Nuestra vida cristiana')}</b></div>
                    <div className="px-4">
                        {getCheckbox(Kind.NVC_1, t('program.assignment', 'Asignación {{n}}', { n: 1 }))}
                        {getCheckbox(Kind.NVC_2, t('program.assignment', 'Asignación {{n}}', { n: 2 }))}
                        {getCheckbox(Kind.NVC_3, t('program.assignment', 'Asignación {{n}}', { n: 3 }))}
                        <div className="mt-2">{getCheckbox(Kind.CONDUCTOR_ESTUDIO_BIBLICO, t('program.bible-study-conductor', 'Conductor Estudio bíblico'))}</div>
                        {getCheckbox(Kind.LECTOR_ESTUDIO_BIBLICO, t('program.bible-study-reader', 'Lector Estudio bíblico'))}
                    </div>
                </>
            )}
        </div>
    );
}

import styled from 'styled-components';
import MenuUser from 'components/layout/MenuUser';

interface Props {
    onMenuClick: () => void,
    hideMenu?: boolean,
}

const Topbar = ({ onMenuClick, hideMenu }: Props): JSX.Element => (
    <TopbarContainer>
        {hideMenu
            ? <div style={{ height: '50px' }} />
            : <MenuButton onClick={onMenuClick}><i className="fas fa-bars" /></MenuButton>}
        <MenuUser />
    </TopbarContainer>
);

export default Topbar;

const TopbarContainer = styled.div`
    background-color: ${(props) => props.theme.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MenuButton = styled.button`
    background-color: ${(props) => props.theme.primary};
    border: none;
    color: white;
    font-size: 30px;
    padding: 0 10px;
    height: 50px;

    &:focus{
        outline: 0;
    }

    &:hover{
        background-color: #00000022;
    }
`;

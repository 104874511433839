import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IUser from 'types/IUser';

const useUser = (id: number): UseQueryResult => {
    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                user(id: "${id}") {
                    id
                    name
                    lastname
                    rol
                    email
                    ultimoacceso
                    primera_vez
                }
            }
        `);

        return response.user;
    };

    return useQuery<IUser, Error>(['user', id], loadData);
};

export default useUser;

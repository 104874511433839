import styled from 'styled-components';
import GeneralTopBar from 'components/layout/GeneralTopBar';
import Footer from 'components/layout/Footer';

interface Props {
    children: React.ReactNode,
}

const GeneralLayout = ({ children }: Props): JSX.Element => (
    <>
        <PageContainer>
            <TopBar>
                <GeneralTopBar />
            </TopBar>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <MainContainer>
                    {children}
                </MainContainer>
            </div>
            <Footer />
        </PageContainer>
    </>
);

export default GeneralLayout;

const PageContainer = styled.div`
    background-color: ${(props) => props.theme.mainBackground};
`;

const TopBar = styled.div`
    height: 50px;
    background-color: ${(props) => props.theme.footerBackground};
    position: relative;
    display: flex;
    justify-content: center;
`;

const MainContainer = styled.div`
    background-color: white;
    box-shadow: 15px 0 15px -4px rgba(204,204,204,0.5), -12px 0 8px -4px rgba(204,204,204,0.5);
    width: 1140px;
    position: relative;
    min-height: calc(100vh - 100px);

    @media (max-width: 576px) {
        min-height: calc(100vh - 110px);
    }

    padding: 0 64px;
    padding-bottom: 50px;

    @media (max-width: 768px){
        padding: 20px;
    }
`;



import { gql } from 'graphql-request';
import API from 'services/api';

const deleteEvento = async (id: number): Promise<boolean> => {
    return await API.graphql(gql`
        mutation {
            deleteEvento(
                id: ${id}
            )
        }
    `);
};

export default deleteEvento;

import * as React from 'react';
import { getSession, saveSession, clearSession, getCongregationId, saveCongregationId } from 'services/session';
import ISession from 'types/ISession';

interface ContextState {
    session: ISession | null,
    initSession: (newSession: ISession) =>  void,
    closeSession: (() => void),
    congregationId: number | null,
    storeCongregationId: (value: number) =>  void,
    selectedDate: Date,
    setSelectedDate: ((value: Date) => void),
}

const defaultState: ContextState = {
    session: getSession(),
    initSession: (newSession: ISession) => saveSession(newSession),
    closeSession: () => clearSession(),
    congregationId: getCongregationId(),
    storeCongregationId: (value: number) => saveCongregationId(value),
    selectedDate: new Date(),
    setSelectedDate: () => { /* empty */ },
};

const MainContext = React.createContext<ContextState>(defaultState);

interface Props {
    children: React.ReactNode
}

export const MainContextProvider = ({ children }: Props): JSX.Element => {
    const [session, setSession] = React.useState<ISession | null>(getSession());
    const [congregationId, setCongregationId] = React.useState<number | null>(getCongregationId());
    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

    const initSession = (newSession: ISession) => {
        saveSession(newSession);
        setSession(newSession);
    };

    const closeSession = () => {
        clearSession();
        setSession(null);
    };

    const storeCongregationId = (value: number) => {
        setCongregationId(value);
        saveCongregationId(value);
    };

    return (
        <MainContext.Provider value={{ session, initSession, closeSession, congregationId, storeCongregationId, selectedDate, setSelectedDate }}>
            {children}
        </MainContext.Provider>
    );
};

export default MainContext;

import * as React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { getDateRangeFormatted } from 'services/utils';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { add, sub } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedDate: Date,
    onChangeDate: (date: Date) => void,
    disabled?: boolean,
}

export default function CalendarBar({selectedDate, onChangeDate, disabled}: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const CalendarButton = React.forwardRef(function f({onClick}: any, ref: any) {
        return (
            <Container>
                <Button onClick={onClick} disabled={disabled} ref={ref}>
                    <i className="far fa-calendar-alt" />
                </Button>
            </Container>
        );
    });

    const handleBackward = () => {
        onChangeDate(sub(new Date(selectedDate), { days: 7 }));
    };

    const handleForward = () => {
        onChangeDate(add(new Date(selectedDate), { days: 7 }));
    };

    return (
        <header className="d-flex justify-content-between align-items-center">
            <h1>{getDateRangeFormatted(selectedDate, currentLanguage)}</h1>

            <div className="d-flex">
                <Arrow onClick={handleBackward}><i className="fas fa-chevron-left" /></Arrow>
                <DatePicker
                    selected={selectedDate}
                    onChange={onChangeDate}
                    withPortal
                    locale={currentLanguage === 'es-US' ? es : en}
                    customInput={<CalendarButton onClick />}
                    calendarStartDay={1}
                    fixedHeight
                    showMonthDropdown
                    todayButton={<div>{t('program.current-week', 'Semana actual')}</div>}
                />
                <Arrow onClick={handleForward}><i className="fas fa-chevron-right" /></Arrow>
            </div>
        </header>
    );
}

const Container = styled.div`
    text-align: right;

    @media (max-width: 768px){
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 15px;
        right: 15px;
        z-index: 1000;
        background-color: ${(props) => props.theme.primary};
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 0;
        box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.5);
    }
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.primary};
    cursor: pointer;
    font-size: 50px;

    &:hover{
        color: ${(props) => props.theme.primaryDarker};
    }

    @media (max-width: 768px){
        color: white;
        font-size: 25px;
    }

    &:disabled{
        color: ${(props) => props.theme.primaryLighter};
        cursor: default;
    }
`;

const Arrow = styled.button`
    color: ${(props) => props.theme.primary};
    font-size: 40px;
    background: none;
    border: none;

    @media (max-width: 768px){
        display: none;
    }

    &:hover{
        color: ${(props) => props.theme.primaryDarker};
    }

    .fa-chevron-left{
        padding-right: 20px;
    }

    .fa-chevron-right{
        padding-left: 20px;
    }
`;

import * as React from 'react';
import styled from 'styled-components';
import { ModalBody, Modal, ModalFooter, Button } from 'reactstrap';
import MainContext from 'components/layout/MainContext';
import { PDFViewer } from '@react-pdf/renderer';
import useReportData from 'hooks/useReportData';
import useSalas from 'hooks/useSalas';
import ISala from 'types/ISala';
import PdfS89Ssingle from 'components/reports/PdfS89Ssingle';
import { useTranslation } from 'react-i18next';

interface Props {
    id: number
}

export default function ViewAssignementS89S({ id }: Props): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const { selectedDate } = React.useContext(MainContext);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const reportData = useReportData(selectedDate, currentLanguage);

    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];

    return (
        <>
            <ButtonStyled onClick={toggle} className="pl-3"><i className="far fa-file-alt" /></ButtonStyled>
            <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
                <ModalBody className="p-4 d-flex flex-column">
                    <PDFViewer height={600}>
                        <PdfS89Ssingle data={reportData} salas={salas} id={id} />
                    </PDFViewer>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="primary" onClick={toggle}>{t('app.close', 'Cerrar')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

const ButtonStyled = styled.button`
    display: inline;
    background: none;
    border: none;
`;

import { Row } from 'reactstrap';
import IEstudiante from 'types/IEstudiante';
import InfoLabel from './InfoLabel';
import { format } from 'date-fns';
import { getUTC } from 'services/utils';
import es from 'date-fns/locale/es';
import { t } from 'i18next';

interface Props {
    data: IEstudiante,
}

export default function PersonalInformation ({ data }: Props): JSX.Element {
    return (
        <Row className="p-4">
            <div style={{fontWeight: 'bold', textAlign: 'right'}} className="mb-4">
                {data.inactivo ? (
                    <div className="red">{t('person.non-available', 'No disponible')}</div>
                ):(
                    <div className="green">{t('person.available', 'Disponible')}</div>
                )}
            </div>
            {data.fecha_nacimiento &&
                <InfoLabel  size="4" label={t('person.birth-date', 'Fecha de nacimiento')} value={format(getUTC(new Date(data.fecha_nacimiento)), 'dd-MMMM-yyyy', { locale: es })}/>
            }
            {data.fecha_bautizado &&
                <InfoLabel  size="4" label={t('person.baptism-date', 'Fecha de bautismo')} value={format(getUTC(new Date(data.fecha_bautizado)), 'dd-MMMM-yyyy', { locale: es })}/>
            }
            {data.fecha_matriculado &&
                <InfoLabel  size="4" label={t('person.enrollment-date', 'Fecha de matriculado')} value={format(getUTC(new Date(data.fecha_matriculado)), 'dd-MMMM-yyyy', { locale: es })}/>
            }
            <InfoLabel  size="4" label={t('person.phone', 'Teléfono')} value={data.telefono}/>
            <InfoLabel  size="4" label={t('person.email', 'Email')} value={data.email}/>
            <InfoLabel  size="4" label={t('person.group', 'Grupo')} value={data.grupo.toString()}/>
            <InfoLabel  size="4" label={t('person.type', 'Tipo')} value={data.tipo}/>
            <InfoLabel  size="4" label={t('person.privilege', 'Privilegio')} value={data.privilegio}/>
            <InfoLabel size="12" label={t('person.addrees', 'Dirección')} value={data.direccion1}/>
            <InfoLabel size="12" label={t('person.address-emergency', 'En caso de emergencias')} value={data.direccion2}/>
            <InfoLabel size="12" label={t('person.observations', 'Observaciones')} value={data.observaciones}/>
        </Row>
    );
}

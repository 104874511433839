import styled from 'styled-components';
import useIsSessionOpen from 'hooks/useIsSessionOpen';
import { Link } from 'react-router-dom';
import iconPerson from 'assets/iconPerson.svg';
import MainLogo from 'components/layout/MainLogo';
import { useTranslation } from 'react-i18next';

export default function GeneralTopBar(): JSX.Element {
    const isSessionOpen = useIsSessionOpen();
    const { t } = useTranslation();

    return (
        <TopBarContainer>
            <MainLogo />
            <div className="d-flex justify-content-end align-items-center h-100 px-2">
                {isSessionOpen && <Link to="/dashboard">{t('dashboard.admin', 'Administrador')}</Link>}
                {!isSessionOpen && (
                    <Link to="/login" className="d-flex align-items-center justify-content-end h-100">
                        <img src={iconPerson} alt="" width="24px" height="24px" />
                        <span className="px-2">{t('dashboard.login', 'Iniciar sesión')}</span>
                    </Link>
                )}
            </div>
        </TopBarContainer>
    );
}

const TopBarContainer = styled.div`
    height: 50px;
    background-color: #cecece;
    border-bottom: 5px solid ${(props) => props.theme.primary};
    padding-right: 10px;
    z-index: 100;
    width: 1140px;
`;

import { getSalaName, isSalaActive } from 'services/utils';
import useSalas from 'hooks/useSalas';
import ISala from 'types/ISala';
import { Kind } from 'services/constants';
import { useTranslation } from 'react-i18next';

interface Props {
    data: string,
    isMale: boolean,
}

export default function InfoCapacities ({ data, isMale }: Props): JSX.Element {
    const capacities = data?.split('|') || [];
    const { data: salasData } = useSalas();
    const salas = salasData as ISala[];
    const { t } = useTranslation();

    if (!salas) return <></>;

    const existSala2 = isSalaActive(2, salas);
    const existSala3 = isSalaActive(3, salas);
    const sala1Name = getSalaName(1, salas);
    const sala2Name = getSalaName(2, salas);
    const sala3Name = getSalaName(3, salas);
    const existExtraSalas = existSala2 || existSala3;


    const getItem = (value: number, label: string) => {
        const checked = capacities.some((o) => o === value.toString());
        if (checked){
            return <div><i className="fas fa-check"/><span className="pl-2">{label}</span></div>;
        }
        return <div><i className="fas fa-minus"/><span className="pl-3">{label}</span></div>;
    };

    return (
        <div className="p-4">
            {isMale && (
                <>
                    <div><b>{t('program.general', 'General')}</b></div>
                    <div className="px-4">
                        {getItem(Kind.PRESIDENCIA, t('program.chairman', 'Presidente'))}
                        {getItem(Kind.ORACION_INICIO, t('program.initial-prayer', 'Oracion inicial'))}
                        {getItem(Kind.ORACION_FINAL, t('program.ending-prayer', 'Oracion final'))}
                        {existSala2 && getItem(Kind.ENCARGADO_SALA_2, t('program.in-charge', 'Encargado {{sala}}', { sala: sala2Name }))}
                        {existSala3 && getItem(Kind.ENCARGADO_SALA_3, t('program.in-charge', 'Encargado {{sala}}', { sala: sala3Name }))}
                    </div>

                    <div className="mt-4"><b>{t('title.treasures', 'Tesoros de la biblia')}</b></div>
                    <div className="px-4 mb-4">
                        {getItem(Kind.DISCURSO, t('program.speach', 'Discurso'))}
                        {getItem(Kind.PERLAS, t('program.spiritual-gems', 'Busquemos perlas'))}
                        {getItem(Kind.LECTURA_SALA_1, t('program.reading', 'Lectura estudiantil'))}
                        {existSala2 && getItem(Kind.LECTURA_SALA_2, t('program.reading', 'Lectura estudiantil {{sala}}', { sala: sala2Name}))}
                        {existSala3 && getItem(Kind.LECTURA_SALA_3, t('program.reading', 'Lectura estudiantil {{sala}}', { sala: sala3Name}))}
                    </div>
                </>
            )}

            <div><b>{t('title.apply-ministry', 'Seamos mejores maestros')}</b></div>
            <div className="px-4">
                {existExtraSalas && <div><i>{sala1Name}</i></div>}
                <div className="mt-2">{getItem(Kind.PARTE_1_SALA_1, t('program.part', 'Parte {{n}}', { n: 1 }))}</div>
                {getItem(Kind.PARTE_1_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                <div className="mt-2">{getItem(Kind.PARTE_2_SALA_1, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                {getItem(Kind.PARTE_2_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                <div className="mt-2">{getItem(Kind.PARTE_3_SALA_1, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                {getItem(Kind.PARTE_3_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 3 }))}
                <div className="mt-2">{getItem(Kind.PARTE_4_SALA_1, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                {getItem(Kind.PARTE_4_AYUDANTE_SALA_1, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
            </div>

            {existSala2 && (
                <div className="px-4 pt-4">
                    <div><i>{sala2Name}</i></div>
                    <div className="mt-2">{getItem(Kind.PARTE_1_SALA_2, t('program.part', 'Parte {{n}}', { n: 1 }))}</div>
                    {getItem(Kind.PARTE_1_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_2_SALA_2, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                    {getItem(Kind.PARTE_2_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_3_SALA_2, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                    {getItem(Kind.PARTE_3_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 3 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_4_SALA_2, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                    {getItem(Kind.PARTE_4_AYUDANTE_SALA_2, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
                </div>
            )}

            {existSala3 && (
                <div className="px-4 pt-4">
                    <div><i>{sala3Name}</i></div>
                    <div className="mt-2">{getItem(Kind.PARTE_1_SALA_3, t('program.part', 'Parte {{n}}', { n: 1 }))}</div>
                    {getItem(Kind.PARTE_1_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 1 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_2_SALA_3, t('program.part', 'Parte {{n}}', { n: 2 }))}</div>
                    {getItem(Kind.PARTE_2_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 2 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_3_SALA_3, t('program.part', 'Parte {{n}}', { n: 3 }))}</div>
                    {getItem(Kind.PARTE_3_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
                    <div className="mt-2">{getItem(Kind.PARTE_4_SALA_3, t('program.part', 'Parte {{n}}', { n: 4 }))}</div>
                    {getItem(Kind.PARTE_4_AYUDANTE_SALA_3, t('program.part-h', 'Parte {{n}} ayudante', { n: 4 }))}
                </div>
            )}

            {isMale && (
                <>
                    <div className="mt-4"><b>{t('title.living-christians', 'Nuestra vida cristiana')}</b></div>
                    <div className="px-4">
                        {getItem(Kind.NVC_1, t('program.assignment', 'Asignación {{n}}', { n: 1 }))}
                        {getItem(Kind.NVC_2, t('program.assignment', 'Asignación {{n}}', { n: 2 }))}
                        {getItem(Kind.NVC_3, t('program.assignment', 'Asignación {{n}}', { n: 3 }))}
                        <div className="mt-2">{getItem(Kind.CONDUCTOR_ESTUDIO_BIBLICO, t('program.bible-study-conductor', 'Conductor Estudio bíblico'))}</div>
                        {getItem(Kind.LECTOR_ESTUDIO_BIBLICO, t('program.bible-study-reader', 'Lector Estudio bíblico'))}
                    </div>
                </>
            )}
        </div>
    );
}

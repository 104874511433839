import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import { Roles } from 'services/constants';
import IUser from 'types/IUser';
import useCongregationId from './useCongregationId';
import useIsRole from './useIsRole';

export default function useUsers(): UseQueryResult{
    const isAdmin = useIsRole(Roles.SUPER_ADMIN);
    const congregationId = useCongregationId();

    const fields = `
        id
        name
        lastname
        rol
        email
        ultimoacceso
        primera_vez
        congregacion{
            nombre
        }
    `;
    const loadData = async () => {
        const query = isAdmin ?
            gql`query {
                allUsers{
                    ${fields}
                }
            }`: gql`query {
                users(congregacion_id: ${congregationId}){
                    ${fields}
                }
            }`;

        const response = await API.graphql(query);
        if (isAdmin) return response.allUsers;
        return response.users;
    };

    return useQuery<IUser[], Error>('allUsers', loadData);
}


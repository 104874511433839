import useIsRole from './useIsRole';
import { Roles } from 'services/constants';
import { getIsEstudiantil } from 'services/utils';

const useCanAssign = (kind: number): boolean => {
    const isSuperAdmin = useIsRole(Roles.SUPER_ADMIN);
    const isCoordinador = useIsRole(Roles.COORDINADOR);
    const isSupVmc = useIsRole(Roles.SUP_VMC);

    if (isSuperAdmin) return true;

    if (getIsEstudiantil(kind)){
        return isSupVmc || isCoordinador;
    }else{
        return isCoordinador;
    }
};

export default useCanAssign;

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IEvento from 'types/IEvento';
import useCongregationId from 'hooks/useCongregationId';

const useEvento = (fecha: string): UseQueryResult => {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                evento(fecha: "${fecha}", congregacion_id: ${congregationId}){
                    tipo
                    observaciones
                }
            }
        `);
        return response.evento;
    };

    return useQuery<IEvento, Error>(['evento', fecha], loadData);
};

export default useEvento;

import useSalas from 'hooks/useSalas';
import ISala from 'types/ISala';
import { SyntheticEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import updateSala from 'hooks/updateSala';
import { useTranslation } from 'react-i18next';

export default function SettingsSalas (): JSX.Element {
    const { data: salasData, isLoading } = useSalas();
    const data = salasData as ISala[];
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const mutationUpdateSala = useMutation(updateSala, {
        onMutate: () => {
            // setIsSubmitting(true);
        },
        onError: () => {
            // ShowToast(t('error.sending-data'), 'error');
        },
        onSettled: () => {
            queryClient.invalidateQueries('allSalas');
            // setIsSubmitting(false);
        },
    });

    const handleSalaChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const id = parseInt(target.id);
        const sala1 = data.find((item: ISala) => item.numero === 1);
        const sala2 = data.find((item: ISala) => item.numero === 2);
        const sala3 = data.find((item: ISala) => item.numero === 3);

        if (!sala1 || !sala2 || !sala3) return;

        if (id === 1) return;

        if (id === 2) {
            if (!target.checked && sala3.activa) {
                sala3.activa = target.checked;
                mutationUpdateSala.mutate(sala3);
            }
            sala2.activa = target.checked;
            mutationUpdateSala.mutate(sala2);
            return;
        }

        if (id === 3 && sala2.activa) {
            sala3.activa = target.checked;
            mutationUpdateSala.mutate(sala3);
        }
    };

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            <small><b>{t('settings.halls', 'Salas')}</b></small>
            <div className="react-bootstrap-table">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>{t('settings.hall-name', 'Nombre')}</th>
                            <th>{t('settings.hall-active', 'Activa')}</th></tr>
                    </thead>
                    <tbody>
                        {data.map((item: ISala) => (
                            <tr key={item.id}>
                                <td>{item.nombre}</td>
                                <td width="70px" style={{textAlign: 'center'}}>
                                    <div className="pretty p-switch p-fill">
                                        <input type="checkbox" id={item.numero.toString()} checked={item.activa} onChange={handleSalaChange} />
                                        <div className="state"><label /></div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

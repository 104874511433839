import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import ISelectOption from 'types/ISelectOption';

interface Props {
    name: string,
    label: string,
    defaultValue?: string,
    value?: string | number,
    className?: string,
    options: ISelectOption[],
    invalid?: boolean,
    onChange?: (value: string | number) => void,
}

export default function Select({ name, label, defaultValue, value, className, options, onChange, invalid }: Props): JSX.Element {
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent) => {
        if (onChange){
            const target = event.target as HTMLInputElement;
            onChange(target.value);
        }
    };

    return (
        <FormGroup className={`mb-2 ${className}`}>
            <Label className="m-0" for={name}><small><b>{label}</b></small></Label>
            <Input name={name} id={name} value={value} defaultValue={defaultValue} type="select" invalid={invalid} onChange={handleChange}>
                {options.map((item: ISelectOption) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                ))}
            </Input>
            <FormFeedback>{t('general.required-field', 'Campo requerido')}</FormFeedback>
        </FormGroup>
    );
}

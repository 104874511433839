import { gql } from 'graphql-request';
import API from 'services/api';
import IEstudiante from 'types/IEstudiante';

const updateEstudiante = async (data: IEstudiante): Promise<IEstudiante> => {
    return await API.graphql(gql`
        mutation {
            updateEstudiante(
                id: ${data.id}
                nombres: "${data.nombres}"
                apellidos: "${data.apellidos}"
                sexo: "${data.sexo}"
                fecha_nacimiento: "${data.fecha_nacimiento}"
                fecha_bautizado: "${data.fecha_bautizado}"
                fecha_matriculado: "${data.fecha_matriculado}"
                telefono: "${data.telefono}"
                email: "${data.email}"
                observaciones: "${data.observaciones}"
                direccion1: "${data.direccion1}"
                direccion2: "${data.direccion2}"
                tipo: "${data.tipo}"
                privilegio: "${data.privilegio}"
                grupo: "${data.grupo}"
                inactivo: ${data.inactivo}
                congregacion_id: ${data.congregacion_id}
                capacidades: "${data.capacidades}"
            ){
                id
            }
        }
    `);
};

export default updateEstudiante;

import styled from 'styled-components';

export default function LoginLogo(): JSX.Element {
    return (
        <Title>
        VMC
            <span className="light-title">| Admin</span>
        </Title>
    );
}

const Title = styled.h1`
    background-color: ${(props) => props.theme.primary};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    font-size: 2.375rem;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    @media (max-width: 350px) {
        font-size: 30px;
    }

    .light-title {
        font-weight: 300;
        padding-left: 10px;
    }
`;

import * as React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import MainContext from 'components/layout/MainContext';
import useIsSessionOpen from 'hooks/useIsSessionOpen';

const useCurrentUser = (): UseQueryResult => {
    const { closeSession } = React.useContext(MainContext);
    const isSessionOpen = useIsSessionOpen();

    const loadData = async () => {
        if (!isSessionOpen) return;

        try{
            const response = await API.getCurrentUser();
            return response.data;
        }catch(error){
            if (error.response.status === 403){
                closeSession();
            }
        }
    };

    return useQuery('currentUser', loadData);
};

export default useCurrentUser;

import { gql } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';
import API from 'services/api';
import IEvento from 'types/IEvento';
import useCongregationId from 'hooks/useCongregationId';

const useEventos = (): UseQueryResult => {
    const congregationId = useCongregationId();

    const loadData = async () => {
        const response = await API.graphql(gql`
            query {
                eventos(congregacion_id: ${congregationId}){
                    id
                    fecha
                    tipo
                    observaciones
                }
            }
        `);
        return response.eventos;
    };

    return useQuery<IEvento[], Error>('allEventos', loadData);
};

export default useEventos;

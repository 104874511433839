
import { gql } from 'graphql-request';
import API from 'services/api';
import IUser from 'types/IUser';

const createUser = async (data: IUser): Promise<IUser> => {
    return await API.graphql(gql`
        mutation {
            createUser(
                name: "${data.name}"
                lastname: "${data.lastname}"
                email: "${data.email}"
                rol: ${data.rol}
                congregacion_id: ${data.congregacion_id}
                password: "${data.password}"
            ){
                id
            }
        }
    `);
};

export default createUser;

import styled from 'styled-components';

export default function MainLogo (): JSX.Element {
    return (
        <Logo>
            <b>VMC</b>
            {'  '}
            <span>| Admin</span>
        </Logo>
    );
}

const Logo = styled.div`
    width: 250px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 30px;
    background-color: ${(props) => props.theme.primary};
    color: white;
    position: absolute;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    span{
        padding-left: 10px;
    }

    @media (max-width: 768px) {
        width: 150px;
        height: 50px;
        font-size: 18px;
        line-height: 18px;
    }
`;
